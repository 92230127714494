import { UserSelf, loadingUserSelf } from "../User";

export type Session = {
  currentUser: UserSelf;
  initialUserFetched: boolean;
  isUpdatingUser: boolean;
};

export const empty: Session = {
  currentUser: loadingUserSelf,
  initialUserFetched: false,
  isUpdatingUser: false,
};

// Selectors
export const currentUser = (session: Session): UserSelf => session.currentUser;

// Helpers
export const isSignedIn = (session: Session): boolean =>
  session.currentUser &&
  !!session.currentUser.id &&
  session.currentUser.id !== -1;
