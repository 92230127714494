import { get } from "storefront/GrailedAPI/request";
import type { User } from "storefront/User";

type Response = {
  data: Array<User>;
};

const unpack = (res: Response): Array<User> => res.data;

const followedSellers = (): Promise<Array<User>> =>
  get<Response>("/api/users/followed_sellers").then(unpack);

export default followedSellers;
