import { camelize } from "camelscore";
import { get } from "storefront/GrailedAPI/request";
import type { Options } from "storefront/GrailedAPI/request/Options";
import type { UserSelf } from "storefront/User";

type Response = {
  data: UserSelf;
};

const unpack = (res: Response): UserSelf => camelize(res.data);

const me = (opts?: Options): Promise<UserSelf | undefined> =>
  get<Response>("/api/users/me", undefined, opts)
    .then(unpack)
    .catch((error) => {
      // eslint-disable-next-line camelcase
      if (error.body?.error?.error_id === "unauthorized") return undefined;
      throw error;
    });

export default me;
