/* eslint-disable no-underscore-dangle */
import React, { ReactNode } from "react";
import { Root, createRoot } from "react-dom/client";
import { uniqueId } from "lodash/fp";
import Flash from "./Flash";
import MessagesStore from "./MessagesStore";
import type { FlashMessage } from "./index";

type NoticeOptions = {
  autoDismiss: boolean;
};

class FlashManager {
  // Notices will stick around for this amount of time before being
  // automatically dismissed - unless you pass `autoDismiss: false`
  private NOTICE_TTL = 15000 as const;

  private _root: Root;

  private target: HTMLElement | null;

  private static instance: FlashManager;

  private constructor(
    private _window: typeof window = window,
    private _store: MessagesStore = new MessagesStore(),
    private _uniqueId: (id: string) => string = uniqueId,
  ) {
    this.generateRoot();

    this.onPageReady(() => this.render(this._store.messages));
    this._store.onChange(this.render);
  }

  /**
   * Returns a singleton instance of the FlashManager. This method ensures that only one instance of the FlashManager is created
   * and used throughout the application. This pattern is beneficial for managing global state, such as flash messages, in a consistent manner.
   *
   * @param {typeof window} [_window] - An optional parameter primarily used for testing purposes. It allows the substitution of the window object,
   * typically for a mock or a stub in a test environment. This parameter should not be provided in actual implementations as the default window object
   * is used.
   * @param {MessagesStore} [_store] - An optional parameter mainly for testing purposes. It allows injecting a custom or mocked MessagesStore instance.
   * This is useful for testing the FlashManager with different states and behaviors of the store. In actual implementations, this parameter is not necessary
   * as the FlashManager will instantiate its own MessagesStore.
   * @param {(id: string) => string} [_uniqueId] - An optional parameter primarily used for testing purposes. This function is used to generate unique
   * identifiers for flash messages. By default, lodash's `uniqueId` function is used. However, in testing scenarios, a custom function can be provided to
   * control or predict the generation of unique IDs.
   *
   * @returns {FlashManager} The singleton instance of FlashManager.
   */
  public static getInstance(
    _window?: typeof window,
    _store?: MessagesStore,
    _uniqueId?: (id: string) => string,
  ): FlashManager {
    if (!this.instance)
      this.instance = new FlashManager(_window, _store, _uniqueId);
    return this.instance;
  }

  private generateRoot = (): void => {
    this.target = this._window.document.getElementById("flash");
    if (!this.target) throw new Error("Could not find #flash element");

    this._root = createRoot(this.target);
  };

  private _render = (reactEl: ReactNode) => {
    // check if flash element has gotten destroyed/moved
    if (!this.target?.isConnected) this.generateRoot();

    this._root.render(reactEl);
  };

  private onPageReady = (fn: () => void): void => {
    if (this._window.document.readyState === "loading")
      return this._window.document.addEventListener("DOMContentLoaded", fn);

    return fn();
  };

  /**
   * Displays an alert message with a fixed ID that does not auto-dismiss.
   *
   * @param {string} message - The message to be displayed in the alert.
   * @returns {string} The unique ID of the alert message.
   */
  alert = (message: string): string =>
    this.add({
      message,
      type: "alert",
      autoDismiss: false,
    });

  /**
   * Displays a notice message with optional auto-dismiss functionality.
   *
   * @param {string} message - The message to be displayed in the notice.
   * @param {NoticeOptions} [options={ autoDismiss: true }] - Options for the notice, including auto-dismiss behavior.
   * @returns {string} The unique ID of the notice message.
   */
  notice = (
    message: string,
    options: NoticeOptions = {
      autoDismiss: true,
    },
  ): string =>
    this.add({
      message,
      type: "notice",
      autoDismiss: options.autoDismiss,
    });

  /**
   * Adds a new flash message to the store and starts a timer for auto-dismiss if enabled.
   *
   * @param {FlashMessage} message - The flash message object to add.
   * @returns {string} The unique ID of the added message.
   */
  add = (message: FlashMessage): string => {
    const id = this._uniqueId(message.id ?? "message");

    const messageWithId = { ...message, id };

    this._store.add(messageWithId);

    if (message.autoDismiss) {
      // NOTE: We don't want the timeout to start until the page is loaded and
      // the Flash component has rendered. [Evan 2017-11-20]
      this.onPageReady(() => {
        this._window.setTimeout(() => this.remove(id), this.NOTICE_TTL);
      });
    }

    return id;
  };

  /**
   * Removes a flash message from the store. If no ID is provided, clears all messages.
   *
   * @param {string} [id] - The unique ID of the message to be removed. If omitted, all messages are removed.
   */
  remove = (id?: string): void => {
    if (id) {
      this._store.remove(id);
    } else {
      this._store.clear();
    }
  };

  private render = (messages: Array<FlashMessage>) =>
    this._render(
      <Flash messages={messages} handleDismissMessage={this.remove} />,
    );
}

export default FlashManager;
