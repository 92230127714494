import type { Options } from "./Options";

export const fromOptions = (
  opts: Options | null | undefined,
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'TimeoutID'.
): [TimeoutID, AbortSignal] => {
  const { timeout = 5000 } = opts || {};
  const controller = new AbortController();
  const { signal } = controller;
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  return [timeoutId, signal];
};
