import { noop } from "lodash/fp";
import type { UserSelf } from "../User";
import isInternalRoute from "../isInternalRoute";
import type { Event } from "./Event";
import type { Tracker } from "./Tracker";

type Unidentified = {
  type: "Unidentified";
  // While unidentified, we don't want to send events to Google; instead we
  // add them to this queue. Once the current user is identified, we track
  // all of the events in the queue in order.
  queue: Array<Event>;
};

const unidentified = (queue: Array<Event>): Unidentified => ({
  type: "Unidentified",
  queue,
});

type Identified = {
  type: "Identified";
};
const identified = (): Identified => ({
  type: "Identified",
});
type State = Unidentified | Identified;

/**
 * @name Analytics.GoogleAdsTracker.GoogleAds
 * @private
 * @description A type signature for the Google Ads library.
 */
class GoogleAdsTracker implements Tracker {
  state: State = unidentified([]);

  gtag: Gtag.Gtag;

  /**
   * @description The Google Ads script is loaded by third_party_js/_google_ads.html.erb or Page/DocumentHead.tsx.
   */
  constructor(win: typeof window = window) {
    win.gtag = win.gtag || noop;
    this.gtag = win.gtag;
  }

  identify = (user?: UserSelf) => {
    const { state } = this;

    if (user) {
      this.gtag("set", "user_data", {
        email: user.email,
      });
    }

    if (state.type === "Unidentified") {
      this.state = identified();
      state.queue.forEach((event) => this.track(event));
    }

    return user;
  };

  /**
   * @description Given a Event, it converts it to an SegmentEvent and gives it to the Segment
   * track method.
   */
  track = (event: Event): Event => {
    const { state } = this;

    if (isInternalRoute()) return event;

    if (state.type === "Unidentified") {
      this.state = unidentified([...state.queue, event]);
      return event;
    }

    if (event.object === "confirmation_page" && event.action === "viewed") {
      // "Confirmation Page Viewed"
      // See: "Web Purchases"
      // https://ads.google.com/aw/conversions/detail?ocid=273670253&ctId=356594833&euid=631546943&__u=7966969607&uscid=273670253&__c=5959209797&authuser=0
      this.gtag("event", "conversion", {
        send_to: "AW-800578442/cR_DCJHphKoBEIq33_0C",
        value: event.properties.soldPrice,
        currency: "USD",
        transaction_id: event.properties.paypalId,
      });

      if (event.properties.orderType === "new") {
        // See: "New Customer Purchase"
        // https://ads.google.com/aw/conversions/detail?ocid=273670253&ctId=944792128&authuser=0&__u=7966969607&__c=5959209797
        this.gtag("event", "conversion", {
          send_to: "AW-800578442/4_EWCMDEwcIDEIq33_0C",
          value: event.properties.soldPrice,
          currency: "USD",
          transaction_id: event.properties.paypalId,
        });
      } else if (event.properties.orderType === "repeat") {
        // See: "Repeat Buyer Purchase"
        // https://ads.google.com/aw/conversions/detail?ocid=273670253&ctId=945595894&authuser=0&__u=7966969607&__c=5959209797
        this.gtag("event", "conversion", {
          send_to: "AW-800578442/kSnBCPbL8sIDEIq33_0C",
          value: event.properties.soldPrice,
          currency: "USD",
          transaction_id: event.properties.paypalId,
        });
      }
    } else if (
      (event.object === "facebook_sign_up" ||
        event.object === "google_sign_up" ||
        event.object === "apple_sign_up" ||
        event.object === "email_sign_up") &&
      event.action === "completed"
    ) {
      // "Email Sign Up Completed"
      // "Google Sign Up Completed"
      // "Facebook Sign Up Completed"
      // "Apple Sign Up Completed"
      // NOTE: this is not being used as a conversion event in Google Ads.
      // https://ads.google.com/aw/conversions/detail?ocid=273670253&ctId=356594833&euid=631546943&__u=7966969607&uscid=273670253&__c=5959209797&authuser=0
      this.gtag("event", "conversion", {
        send_to: "AW-800578442/GaeICMmcprEBEIq33_0C",
      });
    }

    return event;
  };
}

let instance: GoogleAdsTracker | null | undefined;
export const getInstance = (): GoogleAdsTracker => {
  if (!instance) instance = new GoogleAdsTracker();
  return instance;
};
export default GoogleAdsTracker;
