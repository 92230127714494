import type { Capsule } from "../Capsule";

export type Capsules = {
  capsules: {
    data: Array<Capsule>;
    hasMoreCapsules: boolean;
    nextPage: string;
    isLoading: boolean;
  };
  currentCapsule: Capsule | null | undefined;
};
export const empty: Capsules = {
  capsules: {
    data: [],
    hasMoreCapsules: false,
    nextPage: "",
    isLoading: false,
  },
  currentCapsule: null,
};
