import { camelize } from "camelscore";
import GrailedAPIError, {
  fromResponse as grailedErrorFromResponse,
  fromReason as grailedErrorFromReason,
} from "../Error";
import type { Options } from "./Options";
import { fromOptions as headersFromOptions } from "./Headers";
import { fromOptions as timeoutFromOptions } from "./Timeout";
import RequestWatcher from "./RequestWatcher";

const baseUrl =
  process.env.GRAILED_API_BASE_URL ||
  process.env.NEXT_PUBLIC_GRAILED_API_BASE_URL ||
  "";

function parseText<T>(text: string): T | Record<string, never> {
  // We sometimes return 200's with empty bodies and set it to JSON, which isn't valid (should be
  // 204 / not JSON). In the meantime, we skip over empty bodies here
  if (!text) return {};
  return camelize(JSON.parse(text));
}

function parseResponse<T>(rsp: Response): Promise<T> {
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Promise<unknown>' is not assignable to type ... Remove this comment to see the full error message
  return rsp.text().then(parseText);
}

const throwError = (error: GrailedAPIError): never => {
  throw error;
};

export const requestWatcher = new RequestWatcher();

const request = <T>(
  url: string,
  opts: Options | null | undefined,
): Promise<T> => {
  requestWatcher.addRequest(url, opts?.body);

  const { body, method } = opts || {};
  const headers = headersFromOptions(opts);
  // Setting credentials to "include" in development will allow cookies to be included in
  // cross-origin requests so we can login from storefront locally
  // See More: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  const credentials =
    process.env.NODE_ENV === "development" ? "include" : "same-origin";
  const [timeoutId, signal] = timeoutFromOptions(opts);
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'RequestOptions'.
  const options: RequestOptions = {
    body,
    method,
    headers,
    credentials,
    signal,
  };

  const handleResponse = (response: Response): Promise<T> | Promise<never> => {
    clearTimeout(timeoutId);
    if (response.ok)
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Promise<unknown>' is not assignable to type ... Remove this comment to see the full error message
      return parseResponse(response).catch((reason) => {
        return throwError(grailedErrorFromReason(response, options)(reason));
      });
    return grailedErrorFromResponse(options)(response).then(throwError);
  };

  return fetch(baseUrl + url, options)
    .then(handleResponse)
    .finally(() => clearTimeout(timeoutId));
};

export default request;
