/* eslint-disable import/prefer-default-export */
import { SellerRatingTag } from "./SellerRating";

export type SellerScore = {
  soldCount: number;
  ratingCount: number;
  ratingAverage: number | null | undefined;
  tagList: Array<SellerRatingTag>;
};

export const empty: SellerScore = {
  soldCount: 0,
  ratingCount: 0,
  ratingAverage: null,
  tagList: [],
};
