/**
 * @namespace Result
 */

/**
 * @memberof Result
 */
export type Success<A> = {
  _tag: "Success";
  value: A;
};

/**
 * @memberof Result
 */
export function success<A>(value: A): Success<A> {
  return {
    _tag: "Success",
    value,
  };
}

/**
 * @memberof Result
 */
export type Failure<E> = {
  _tag: "Failure";
  error: E;
};

/**
 * @memberof Result
 */
export function failure<E>(error: E): Failure<E> {
  return {
    _tag: "Failure",
    error,
  };
}

/**
 * @memberof Result
 */
export type Result<E, A> = Failure<E> | Success<A>;
