import { Result, success, failure } from "./index";

/**
 * @memberof Result
 * @description
 * Converts a function that might throw into a function that returns a Result.
 *
 * In the native catch statement, the error's type is unknown. We require an onThrow function to transform the unknown error to a known type before wrapping it in the Failure. This is designed to prevent weakly typed values from spreading throughout the codebase.
 */
const tryCatch =
  <E>(onThrow: (error: unknown) => E) =>
  <A extends Array<unknown>, R>(fn: (...args: A) => R) =>
  (...args: A): Result<E, R> => {
    try {
      return success(fn(...args));
    } catch (error) {
      return failure(onThrow(error));
    }
  };

export default tryCatch;
