/**
 * @name memoize
 * @memberof Function
 * @description A properly-typed memoization function; no matter how many times the returned
 * function is called, the value from the first call will be returned and the memoized function
 * will not be called again.
 */
const memoize = <A extends Array<unknown>, B>(
  fn: (...args: A) => B,
): ((...args: A) => B) => {
  let cache: B | undefined;

  return (...args: A): B => {
    if (!cache) cache = fn(...args);
    return cache;
  };
};

export default memoize;
