import * as Sentry from "@sentry/browser";

// Stores the initialized Sentry
let instance: typeof Sentry | null = null;

/**
 * @name Sentry.getInstance
 * @description Returns and initialized Sentry (error tracking service)
 */
export const getInstance = (
  dsn: string | undefined = process.env.STOREFRONT_SENTRY_DSN,
): typeof Sentry => {
  if (instance === null) {
    Sentry.init({
      dsn,
      ignoreErrors: [
        /TypeError: t is not a function. \(In 't\(\)', 't' is undefined\)$/,
      ],
    });
    instance = Sentry;
  }

  return Sentry;
};
