import type { State } from "../../../Onboarding/State";

export const ONBOARDING_MY_GRAILS_TOOLTIP_MOUNTED: "ONBOARDING_MY_GRAILS_TOOLTIP_MOUNTED" =
  "ONBOARDING_MY_GRAILS_TOOLTIP_MOUNTED";
export type TooltipMountedAction = {
  type: typeof ONBOARDING_MY_GRAILS_TOOLTIP_MOUNTED;
  payload: State;
};
export const tooltipMountedActionCreator = (
  payload: State,
): TooltipMountedAction => ({
  type: ONBOARDING_MY_GRAILS_TOOLTIP_MOUNTED,
  payload,
});
