import { memoizedMeRequest } from "storefront/hooks/useAuthentication";
import type { UserSelf } from "storefront/User";
import type { Followings } from "storefront/Followings";
import getFollowingsFromAPI from "storefront/Followings/getFromAPI";
import type { InitialCurrentUser } from "storefront/InitialCurrentUser";

const getAndMergeWithFollowings = (
  currentUser: UserSelf,
): Promise<InitialCurrentUser> =>
  getFollowingsFromAPI().then(
    (following: Followings): InitialCurrentUser => ({
      ...currentUser,
      following,
    }),
  );

const maybe =
  <A, B>(fn: (a: A) => B) =>
  (a: A | null | undefined): B | undefined =>
    a ? fn(a) : undefined;

const getInitialCurrentUser = (): Promise<InitialCurrentUser | undefined> =>
  memoizedMeRequest().then(maybe(getAndMergeWithFollowings));

export default getInitialCurrentUser;
