import i18n from "i18next";
import { propOr } from "lodash/fp";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { underscorify } from "camelscore";
import getFeaturesStatuses from "storefront/GrailedAPI/v1/Users/Segment/getFeaturesStatuses";
import { IndexedList } from "../lib/IndexedList";
import { UserSelf } from "../User";
import { isKnownBot } from "./isKnownBot";
import { Event, PropertyValue, getName } from "./Event";
import { Tracker } from "./Tracker";
import isInternalRoute from "../isInternalRoute";
import Service from "./Service";
import QueueTracker from "./QueueTracker";
import Properties from "./Properties";
import buildSegmentIntegrations from "./buildSegmentIntegrations";

export const analytics = new AnalyticsBrowser();

/**
 * @name Analytics.SegmentTracker.getProperties
 * @private
 * @description Converts an Event into an SegmentEvent.
 */
export const getProperties = (event: Event): IndexedList<PropertyValue> => {
  switch (event.object) {
    case "followed designers":
    case "start_verify_email":
      return {
        marketplace: i18n.t("MARKETPLACE_ID"),
      };

    case "manage_saved_searches":
      return {
        ...(event.properties || {}),
        marketplace: i18n.t("MARKETPLACE_ID"),
      };

    default:
      return { ...event.properties, marketplace: i18n.t("MARKETPLACE_ID") };
  }
};

export class SegmentService implements Service {
  ready = (onReady: () => void) => {
    onReady();
  };

  track = (event: Event): Event => {
    if (isInternalRoute()) return event;
    if (isKnownBot()) return event;

    const name = getName(event);

    switch (name) {
      case "Page Viewed":
        analytics.page(
          window.location.pathname.split("/")[1] || "homepage",
          undefined,
          {
            self_referencing_url: window.location.href, // Used to track actual url as Segment passes the canonical_url as "url"
          },
        );
        break;

      // This event also maps to the
      // FB Pixel standard event `Purchase`
      // which requires soldPrice to be in the key `value`
      case "Confirmation Page Viewed":
        analytics.track(
          name,
          underscorify({
            ...getProperties(event),
            value: propOr("", "properties.soldPrice", event),
          }),
        );
        break;

      case "Order Started Clicked":
        break;

      case "Search Filter Changed":
        break;

      default:
        analytics.track(name, underscorify(getProperties(event)));
        break;
    }

    return event;
  };

  identify = (
    user?: UserSelf,
    properties: Properties = {},
    onIdentified: () => void = () => {},
  ) => {
    if (isKnownBot()) return undefined;

    let features = {};

    (async () => {
      try {
        features = await getFeaturesStatuses();
      } catch {
        // Do nothing if call to get Feature Flag Statuses fails
      } finally {
        analytics.identify(
          user?.id ? `${user?.id}` : undefined,
          { ...properties, features },
          onIdentified,
        );
      }
    })();

    return user;
  };
}

let instance: Tracker | undefined;

export const getInstance = (consentGroups: Array<string>): Tracker => {
  if (!instance) {
    const segmentWriteKey =
      process.env.SEGMENT_WRITE_KEY ||
      process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

    const consentBasedIntegrations = buildSegmentIntegrations(consentGroups);

    // block Segment when tokens are in URL (gets set in Rails)
    const disable = window?.doNotTrack === true;

    analytics
      .load(
        { writeKey: `${segmentWriteKey}` },
        {
          integrations: {
            All: true,
            ...consentBasedIntegrations,
          },
          disable,
        },
      )
      // eslint-disable-next-line no-console
      .catch((e: Error) => console.error("Error loading Segment", e));
    const service = new SegmentService();
    instance = new QueueTracker(service);
  }

  return instance;
};
