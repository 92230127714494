import type { Designer } from "../Designer";
import type { SizesByCategory } from "../Size";
import type { SavedSearch } from "../SavedSearch";
import type { ManageSavedFiltersModal } from "../Modal";
import type { Categories } from "../Categories";

/**
 * DEPRECATED
 */
export type Filters = {
  appliedSearchId: string | null | undefined;
  categories: Categories;
  designerSearchQuery: string;
  filteredDesigners: Array<Designer>;
  manageSavedFiltersModal: ManageSavedFiltersModal;
  savedSearches: Array<SavedSearch>;
  searchQuery: string;
  sizes: SizesByCategory;
  sort: string;
};
export const empty: Filters = {
  appliedSearchId: null,
  categories: {},
  designerSearchQuery: "",
  filteredDesigners: [],
  manageSavedFiltersModal: {
    isOpen: false,
    editingUUID: null,
  },
  savedSearches: [],
  searchQuery: "",
  sizes: {},
  sort: "",
};
// Selectors - DEPRECATED
export const categories = (filters: Filters): Categories => filters.categories;
