import { isEmpty } from "lodash";
import { stringify } from "qs";
import { underscorify } from "camelscore";
import { compose } from "storefront/lib/Function";
import type { Options } from "./Options";
import request from "./request";

const dataToBody = compose<any, any, BodyInit>(JSON.stringify)(underscorify);

const stringifyParams = (data: any): string =>
  stringify(data, {
    arrayFormat: "brackets",
  });

const dataToParams = compose<any, any, string>(stringifyParams)(underscorify);
export const get = <T>(
  url: string,
  data: Record<string, any> = {},
  opts?: Options,
): Promise<T> => {
  const params = dataToParams(data);
  const urlWithParams =
    isEmpty(data) || isEmpty(params) ? url : `${url}?${dataToParams(data)}`;
  return request(urlWithParams, { ...opts, method: "get" });
};
export const post = <T>(
  url: string,
  data?: Record<string, any>,
  opts?: Options,
): Promise<T> =>
  request<T>(url, { ...opts, method: "post", body: dataToBody(data) });

export const put = <T>(
  url: string,
  data: Record<string, any> = {},
  opts?: Options,
): Promise<T> =>
  request<T>(url, { ...opts, method: "put", body: dataToBody(data) });
export const del = <T>(
  url: string,
  data: Record<string, any> = {},
  opts?: Options,
): Promise<T> =>
  request<T>(url, { ...opts, method: "delete", body: dataToBody(data) });
export const patch = <T>(
  url: string,
  data: Record<string, any> = {},
  opts?: Options,
): Promise<T> =>
  request<T>(url, { ...opts, method: "PATCH", body: dataToBody(data) });
export default request;
