import React, { useEffect, useState } from "react";
import classNames from "classnames";
import FlashBanner from "storefront/components/Banners/FlashBanner";
import type { FlashMessage } from "./index";

import styles from "./Flash.module.scss";
import containerStyles from "./FlashContainer.module.scss";

type Props = {
  messages: Array<FlashMessage>;
  handleDismissMessage: (id: string | undefined) => void;
};

type HeaderMode =
  | "regularNavBar"
  | "stickyNavBar"
  | "noNavBar"
  | "mobileConversation";

const Flash = ({ messages, handleDismissMessage }: Props) => {
  const [headerMode, setHeaderMode] = useState<HeaderMode>("regularNavBar");

  const isInConversationMode = /^\/messages\/\w+$/.test(
    window.location.pathname,
  );

  useEffect(() => {
    const globalHeader = document.getElementById("globalHeader");
    const globalHeaderWrapper = document.getElementById("globalHeaderWrapper");

    if (isInConversationMode) {
      setHeaderMode("mobileConversation");
    } else if (!globalHeader && !globalHeaderWrapper) {
      setHeaderMode("noNavBar");
    } else if (globalHeader?.style.position === "sticky") {
      setHeaderMode("stickyNavBar");
    } else {
      setHeaderMode("regularNavBar");
    }
  }, [isInConversationMode]);

  useEffect(() => {
    const flashContainer = document.getElementById("flash");

    if (flashContainer) {
      flashContainer.className = classNames(containerStyles.flashContainer, {
        [containerStyles.mobileConversation]:
          headerMode === "mobileConversation",
        [containerStyles.stickyNavBar]: headerMode === "stickyNavBar",
        [containerStyles.noNavBar]: headerMode === "noNavBar",
      });
    }
  }, [headerMode]);

  return (
    <div className={styles.flash}>
      {messages.map(({ id, message, type }) => (
        <FlashBanner
          key={id}
          id={id}
          message={message}
          onDismissClick={() => handleDismissMessage(id)}
          type={type}
        />
      ))}
    </div>
  );
};

export default Flash;
