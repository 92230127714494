import followedListings from "storefront/GrailedAPI/v1/Users/followedListings";
import followedSellers from "storefront/GrailedAPI/v1/Users/followedSellers";
import type { Id } from "storefront/lib/Id";
import type { Followings } from "./index";

const getId = ({ id }: { id: Id }): Id => id;

const getFromAPI = (): Promise<Followings> =>
  Promise.all([followedListings(), followedSellers()]).then(
    ([listings, users]) => ({
      listings: listings.map(getId),
      users: users.map(getId),
    }),
  );

export default getFromAPI;
