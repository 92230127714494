import { camelize } from "camelscore";
import {
  get as getFromCache,
  put as putToCache,
} from "storefront/lib/memoryCache";
import type { PublicConfig } from "../../../Config/PublicConfig";
import { get } from "../../request";

type Response = {
  data: PublicConfig;
};

const CACHE_KEY = "public_config";

const unpack = ({ data }: Response): PublicConfig => data;

export const updateCache = (data: PublicConfig): void =>
  putToCache(CACHE_KEY, data);

const handleErrorForStorybook = (err: Error): PublicConfig => {
  if (process.env.STORYBOOK_USE_WINDOW_CONFIG === "true")
    return camelize(window.PUBLIC_CONFIG);
  throw err;
};

const getConfig = (): Promise<PublicConfig> => {
  if (typeof window !== "undefined" && window.PUBLIC_CONFIG) {
    const windowValue = camelize(window.PUBLIC_CONFIG);
    updateCache(windowValue);
    return Promise.resolve(windowValue);
  }
  const cachedValue = getFromCache(CACHE_KEY);
  if (cachedValue) {
    return Promise.resolve(cachedValue);
  }
  return get<Response>("/api/config")
    .then(unpack)
    .then((data) => {
      putToCache(CACHE_KEY, data);
      return data;
    })
    .catch(handleErrorForStorybook);
};

export default getConfig;
