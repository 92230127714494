import { RequestOptions } from "http";
import { GrailedError } from "./GrailedError";

type Response<T> = {
  readonly status: number;
  readonly statusText: string;
  readonly url: string;
  readonly json: () => Promise<T>;
};

/**
 * GrailedAPIError is thrown when a request to the Grailed API fails.
 */
export default class GrailedAPIError extends Error {
  response: Response<unknown>;

  options: RequestOptions | null | undefined;

  body: {
    error?: GrailedError;
  };

  constructor(
    response: Response<unknown>,
    body: {
      error?: GrailedError;
    },

    options: RequestOptions | null | undefined,
  ) {
    const { status, statusText, url } = response;
    const message = `${status} ${statusText} for ${url}`;
    super(message);
    this.stack = new Error().stack;
    this.name = "GrailedAPIError";
    this.response = response;
    this.options = options;
    this.body = body;
  }
}

const fromJSON =
  (response: Response<unknown>, options: RequestOptions) =>
  (json: Record<string, any>): GrailedAPIError =>
    new GrailedAPIError(response, json, options);

export const fromReason =
  (response: Response<unknown>, options: RequestOptions) =>
  (reason: any): GrailedAPIError =>
    new GrailedAPIError(response, reason.body || {}, options);

export const fromResponse =
  (options: RequestOptions) =>
  (response: Response<unknown>): Promise<GrailedAPIError> =>
    response
      .json()
      .then(fromJSON(response, options))
      .catch(fromReason(response, options));
