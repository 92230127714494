import { compose } from "lodash/fp";
import * as uuid from "uuid";
import { Opaque } from "type-fest";

/**
 * BindingOfferDeviceId is an opaque type, which means that (outside of this file) you can't use any string as a BindingOfferDeviceId, you have to use the generate function provided here.
 */
export type BindingOfferDeviceId = Opaque<string>;
const LOCAL_STORAGE_KEY = "binding_offer_device_id";

/**
 * Retrieves a BindingOfferDeviceId from local storage, if one is stored there.
 */
export const retrieve = ({ localStorage }: typeof window = window):
  | BindingOfferDeviceId
  | null
  | undefined => {
  try {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return localStorage.getItem(LOCAL_STORAGE_KEY) as BindingOfferDeviceId;
  } catch (e) {
    return null;
  }
};

/**
 * Generates a BindingOfferDeviceId. This is the only way to create a BindingOfferDeviceId from scratch.
 */
export const generate = (): BindingOfferDeviceId =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  uuid.v4() as BindingOfferDeviceId;

/**
 * Stores a BindingOfferDeviceId in local storage and returns the given BindingOfferDeviceId, but only if it is stored in local storage successfully.
 */
export const store = (
  value: BindingOfferDeviceId,
  { localStorage }: typeof window = window,
): BindingOfferDeviceId | null | undefined => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, value);
    return value;
  } catch (e) {
    return null;
  }
};
export const generateAndStore: () => BindingOfferDeviceId | null | undefined =
  compose([store, generate]);
export const toString = (id: BindingOfferDeviceId): string => id;
