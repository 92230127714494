/* eslint-disable no-underscore-dangle */
import { UserSelf } from "storefront/User";
import { Event } from "./Event";
import { Tracker } from "./Tracker";
import Service from "./Service";
import Properties from "./Properties";

type Initializing = {
  _tag: "Initializing";
  trackQueue: Array<Event>;
  identifyQueue: Array<[UserSelf | undefined, Properties | undefined]>;
};
const initializing = (
  identifyQueue: Array<[UserSelf | undefined, Properties | undefined]>,
  trackQueue: Array<Event>,
): Initializing => ({
  _tag: "Initializing",
  trackQueue,
  identifyQueue,
});

type Ready = {
  _tag: "Ready";
  trackQueue: Array<Event>;
};
const ready = (trackQueue: Array<Event>): Ready => ({
  _tag: "Ready",
  trackQueue,
});

type Identified = {
  _tag: "Identified";
};
const identified = (): Identified => ({
  _tag: "Identified",
});

type State = Initializing | Ready | Identified;

/**
 * @memberof Analytics
 * @description This is a utility tracker than handles the statefulness lurking within many
 * third-party tracking services. Often, we need to wait for the service to initialize before we
 * can call *any* method and then we need to wait until we've identified before we can start
 * tracking events.
 */
class QueueTracker implements Tracker {
  service: Service;

  state: State = initializing([], []);

  constructor(service: Service) {
    this.service = service;

    this.service.ready(() => {
      if (this.state._tag !== "Initializing") return;

      const { identifyQueue, trackQueue } = this.state;
      this.state = ready(trackQueue);

      identifyQueue.forEach(([user, properties]) => {
        this.identify(user, properties);
      });
    });
  }

  identify = (user?: UserSelf, properties?: Properties) => {
    switch (this.state._tag) {
      case "Initializing":
        this.state = initializing(
          [...this.state.identifyQueue, [user, properties]],
          this.state.trackQueue,
        );
        return user;

      case "Ready": {
        this.service.identify(user, properties, () => {
          if (this.state._tag !== "Ready") return;

          const { trackQueue } = this.state;

          this.state = identified();
          trackQueue.forEach((event) => this.track(event));
        });

        return user;
      }

      case "Identified":
        this.service.identify(user, properties);
        return user;

      default:
        return user;
    }
  };

  track = (event: Event): Event => {
    switch (this.state._tag) {
      case "Initializing":
        this.state = initializing(this.state.identifyQueue, [
          ...this.state.trackQueue,
          event,
        ]);
        return event;

      case "Ready":
        this.state = ready([...this.state.trackQueue, event]);
        return event;

      case "Identified":
        return this.service.track(event);

      default:
        return event;
    }
  };
}

export default QueueTracker;
