import type { User } from "storefront/User";

export const UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS" = "UPDATE_USER_SUCCESS";
export type UpdateUserSuccessAction = {
  type: typeof UPDATE_USER_SUCCESS;
  payload: User | null | undefined;
};
export const updateUserSuccessActionCreator = (
  payload: User | null | undefined,
): UpdateUserSuccessAction => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});
