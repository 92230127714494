import { ADVERTISING, PERFORMANCE } from "storefront/lib/OneTrust/Categories";

const buildSegmentIntegrations = (consentGroups: Array<string>) => {
  let modifiableIntegrations = {};

  if (!consentGroups.includes(PERFORMANCE)) {
    modifiableIntegrations = {
      ...modifiableIntegrations,
      "Actions Amplitude": false,
      "Google Analytics": false,
      "Google Analytics 4": false,
    };
  }

  if (!consentGroups.includes(ADVERTISING)) {
    modifiableIntegrations = {
      ...modifiableIntegrations,
      "Bing Ads": false,
      "Facebook Pixel": false,
      "Google Tag Manager": false,
    };
  }

  return modifiableIntegrations;
};

export default buildSegmentIntegrations;
