export type SellForm = {
  designersRequiringSneakerId: Array<number>;
  maxPrice: number;
  minPrice: number;
};

export const initialState: SellForm = {
  designersRequiringSneakerId: [],
  maxPrice: 0,
  minPrice: 0,
};
