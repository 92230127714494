import type { Result } from "./index";

/**
 * @memberof Result
 * @description
 * Provides a convienient way to extract a value out of a Result.
 */
function extract<E, A, B>(
  onFailure: (e: E) => B,
  onSuccess: (a: A) => B,
): (result: Result<E, A>) => B {
  return (result) =>
    result._tag === "Failure"
      ? onFailure(result.error)
      : onSuccess(result.value);
}

export default extract;
