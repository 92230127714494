import { v4 as uuid } from "uuid";
import { Id } from "storefront/lib/Id";
import { safelyGetItem, safelySetItem } from "storefront/lib/Storage";

/**
 * @namespace VisitorId
 */

/**
 * @private
 */
const KEY = "grailed_visitor_id";

/**
 * @private
 */
interface HasLocalStorage {
  localStorage: Storage;
}

/**
 * @memberof VisitorId
 * @description
 * Visitor ids are used across the platform to identify visitors. A visitor id can be assigned
 * before the visitor has been authenticated and is stored in their browser so that we can continue
 * to identify that visitor across multiple sessions. If the visitor is authenticated, we keep
 * using the same visitor id (in conjuction with their user id) to keep their behavior together.
 *
 * Keep in mind that a visitor id will *not* persist across devices. One human being will be
 * associated with multiple visitor ids.
 *
 * Visitor ids adhere to the UUIDv4 spec, RFC4122[1].
 *
 * NOTE: Visitor ids are extremely important to a bunch of our analytics (Segment, etc.)
 * Please do not change the implementation of this function without extreme care!
 *
 * [1] https://datatracker.ietf.org/doc/html/rfc4122
 */
export const getVisitorId = ({ localStorage }: HasLocalStorage): Id => {
  const cachedId = safelyGetItem(localStorage)(KEY);
  if (cachedId) return cachedId;

  const newId = uuid();
  safelySetItem(localStorage)(KEY, newId);
  return newId;
};
