import type { CategoriesV2 } from "storefront/Categories";
import type { SizesByCategoryV2 } from "storefront/Size";
import Fetcher from "storefront/GrailedAPI/Fetcher";
import { get } from "storefront/GrailedAPI/request";

export const CACHE_KEY = "get_listing_categories_from_config";

export type ResponseData = {
  categories: CategoriesV2;
  sizes: SizesByCategoryV2;
};

type Response = {
  data: ResponseData;
};

const unpack = ({ data }: Response): ResponseData => data;

const getCategories = (): Promise<Response> =>
  get<Response>("/api/config/categories");

const getCategoriesFetcher = new Fetcher(unpack, getCategories, CACHE_KEY);

export default getCategoriesFetcher.call;
