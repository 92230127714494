import React, { useState } from "react";
import classnames from "classnames";
import type { FlashBannerType } from "storefront/lib/flash/index";
import Linkify from "storefront/lib/Linkify";
import onEnterAndSpace from "storefront/lib/onEnterAndSpace";
import styles from "./FlashBanner.module.scss";

export type Props = {
  message: string;
  type: FlashBannerType;
  onDismissClick: (arg0: React.SyntheticEvent<HTMLElement>) => void;
  id?: string;
};

const grabTypeClassName = (type: FlashBannerType): string => {
  if (type === "alert") return styles.alert;
  if (type === "info") return styles.info;
  return styles.notice;
};

const className = (type: FlashBannerType, dismissing: boolean): string => {
  const bannerType = grabTypeClassName(type);
  return classnames(styles.banner, bannerType, {
    [styles.dismissing]: dismissing,
  });
};

const FlashBanner = ({ message, type, onDismissClick, id }: Props) => {
  const [dismissing, setDismissing] = useState<boolean>(false);

  const closeBanner = (event: React.SyntheticEvent<HTMLElement>): void => {
    setDismissing(true);
    setTimeout(() => {
      onDismissClick(event);
    }, 250);
  };

  return (
    <div className={className(type, dismissing)} data-cy="FlashBanner">
      <Linkify
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        properties={{
          target: "_blank",
          rel: "noopener noreferrer nofollow",
          className: `${styles.link}`,
        }}
      >
        {message}
      </Linkify>
      <span
        role="button"
        tabIndex={0}
        onKeyDown={onEnterAndSpace(closeBanner)}
        onClick={closeBanner}
        className={styles.closeButton}
        data-testid={`flash-dismiss-${id}`}
      >
        ✕
      </span>
    </div>
  );
};

export default FlashBanner;
