/* eslint no-underscore-dangle: ["error", { "allow": ["_sift"] }] */
import type { IndexedList } from "../lib/IndexedList";
import type { UserSelf } from "../User";
import isInternalRoute from "../isInternalRoute";
import getSessionId from "../GrailedAPI/v1/Session/id";
import { getName } from "./Event";
import type { Event } from "./Event";
import type { Tracker } from "./Tracker";

/**
 * @name Analytics.SiftScienceTracker.Name
 * @private
 * @description A union type of all of the available names for lines that can be pushed to Retention
 * Science.
 */
type Name = "_setAccount" | "_setUserId" | "_setSessionId" | "_trackPageview";

/**
 * @name Analytics.SiftScienceTracker.Params
 * @private
 * @description A type definition for the params that can be passed to a line.
 */
type Params = string | number | IndexedList<string | number>;

/**
 * @name Analytics.SiftScienceTracker.Line
 * @private
 * @description Each line must have a name and can optionally have some params.
 */
type Line = [Name] | [Name, Params];

const noTracking = ({ _sift }: typeof window) => !_sift || _sift.length < 3;
/**
 * @name Analytics.SiftScienceTracker
 */

class SiftScienceTracker implements Tracker {
  user: UserSelf;

  sift: Array<Line>;

  constructor() {
    window._sift = window._sift || [];
    this.sift = window._sift;
  }

  /**
   * @private
   * @description Every time we build up lines for an event, we must begin by including the SiftScience
   * account ID and user details.
   */
  setupTracking: (arg0: void) => Promise<void> = () =>
    getSessionId()
      .then((sessionId) => {
        this.sift.push(["_setSessionId", sessionId]);
        this.sift.push(["_setUserId", this?.user?.id || ""]);
        this.sift.push(["_trackPageview"]);
      })
      .catch(() => {});

  /**
   * @description We don't want to track events for internal pages.
   * We only have specific events that we want to tell SiftScience about,
   * and we accomplish this by switching on the event type.
   */
  track: (arg0: Event) => Event = (event) => {
    if (isInternalRoute()) return event;

    switch (getName(event)) {
      case "Page Viewed":
        if (noTracking(window)) this.setupTracking();
        break;

      case "Confirmation Page Viewed":
        break;

      default:
        break;
    }

    return event;
  };

  /**
   * @description SiftScience doesn't have an identify method in the same way that other trackers do
   * because in SiftScience we need to identify the user on every event
   * (see SiftScienceTracker#setupTrack). In this method, we set the user on the
   * SiftScienceTracker instance and send a page viewed event.
   */
  identify = (user?: UserSelf) => {
    if (user) {
      this.user = user;
    }

    return user;
  };
}

let instance: SiftScienceTracker | null | undefined;
export const getInstance = (): SiftScienceTracker => {
  if (!instance) instance = new SiftScienceTracker();
  return instance;
};
export default SiftScienceTracker;
