import {
  retrieve as retrieveDeviceId,
  toString as deviceIdToString,
  generateAndStore as generateAndStoreDeviceId,
} from "storefront/BindingOfferDeviceId";
import { v4 as uuidv4 } from "uuid";
import getCSRFToken from "storefront/lib/getCSRFToken";
import {
  safelyGetItem,
  safelySetItem,
  safelyRemoveItem,
} from "storefront/lib/Storage";
import getCookie from "storefront/lib/getCookie";
import type { Options } from "./Options";

export const AMPLITUDE_LOCAL_STORAGE_KEY = "analytics_session_id";
export const RISKIFIED_CART_TOKEN_KEY = "riskified-cart-token";

const DEFAULT_VERSION = 1;

export const fromOptions = (
  opts: Options | null | undefined,
  win?: typeof window,
): HeadersInit => {
  const w = win || (typeof window === "undefined" ? undefined : window);

  const {
    addPathHeader = false,
    addRiskifiedSessionId = false,
    addAuthHeader = false,
    version = DEFAULT_VERSION,
  } = opts || {};

  const headers: HeadersInit = {};

  headers.Accept = "application/json";
  headers["Content-Type"] = "application/json";
  headers["Accept-Version"] = `v${version}`;

  if (typeof opts?.headers?.cookie === "string") {
    headers.cookie = opts.headers.cookie;

    if (addAuthHeader)
      headers.authorization = `Bearer ${getCookie(
        "grailed_jwt",
        headers.cookie,
      )}`;
  }

  const csrfToken = getCSRFToken();

  if (csrfToken) headers["X-CSRF-Token"] = csrfToken;

  /**
   * Only works when Analytics.js 2.0 is enabled on Segment.
   *
   * Analytics.js 2.0 manages the session ID creation for Amplitude, which means
   * we don't have API access to it outside of local storage (yet). We'll have to rely
   * on Segment to keep using this going forward.
   *
   * https://github.com/segmentio/action-destinations/tree/main/packages/browser-destinations/src/destinations/amplitude-plugins
   */

  if (w) {
    const deviceId = retrieveDeviceId() || generateAndStoreDeviceId();
    if (deviceId) headers["Device-Id"] = deviceIdToString(deviceId);

    const amplitudeSessionId = safelyGetItem(w.localStorage)(
      AMPLITUDE_LOCAL_STORAGE_KEY,
    );
    if (amplitudeSessionId) headers["X-AMPLITUDE-ID"] = amplitudeSessionId;

    if (addPathHeader && w.location) headers["USER-PATH"] = w.location.pathname;

    if (addRiskifiedSessionId) {
      const sessionId = safelyGetItem(w.localStorage)(RISKIFIED_CART_TOKEN_KEY);

      if (sessionId) {
        headers[RISKIFIED_CART_TOKEN_KEY] = sessionId;
        safelyRemoveItem(w.localStorage)(RISKIFIED_CART_TOKEN_KEY);
        safelySetItem(w.localStorage)(RISKIFIED_CART_TOKEN_KEY, uuidv4());
      }
    }
  }

  return headers;
};
