import type { Reducer } from "redux";
import { propOr, includes } from "lodash/fp";
import type { User } from "storefront/User";
import { UPDATE_USER_SUCCESS } from "storefront/_app/UpdateUserSuccessAction";
import type { UpdateUserSuccessAction } from "storefront/_app/UpdateUserSuccessAction";
import type { FollowClicked as FollowClickedAction } from "storefront/components/ListingPage/Likes/Action/Types";
import { FOLLOW_CLICKED } from "storefront/components/ListingPage/Likes/Action/Constants";
import { LISTING_TOOLTIP_CLICKED } from "../components/Onboarding/MyGrails/ListingTooltipClickedAction";
import { HEADER_TOOLTIP_CLICKED } from "../components/Onboarding/MyGrails/HeaderTooltipClickedAction";
import { ONBOARDING_MY_GRAILS_TOOLTIP_MOUNTED } from "../components/Onboarding/MyGrails/TooltipMountedAction";
import type { ListingTooltipClickedAction } from "../components/Onboarding/MyGrails/ListingTooltipClickedAction";
import type { HeaderTooltipClickedAction } from "../components/Onboarding/MyGrails/HeaderTooltipClickedAction";
import type { Onboarding } from "./index";
import type { State as OnboardingState } from "./State";
import { started, unstarted, showHeaderTooltip, completed } from "./State";

export type Action =
  | ListingTooltipClickedAction
  | HeaderTooltipClickedAction
  | FollowClickedAction
  | UpdateUserSuccessAction;

const getStateAfterUserLoad = (
  previousState: OnboardingState,
  user: User | null | undefined,
): OnboardingState => {
  const isUserLoggedIn = user && user.id;

  if (!isUserLoggedIn) {
    return previousState;
  }

  const followedCount = propOr(0, "followedListings.length", user);

  // we force completion if the page loads with an intermediate onboarding state
  if (
    includes(previousState, [started, showHeaderTooltip]) ||
    followedCount > 0
  ) {
    return completed;
  }

  const isListingPage = window.location.pathname.match(/^\/listings/);

  if (previousState === unstarted && isListingPage && followedCount === 0) {
    return started;
  }

  return previousState;
};

const initialState: Onboarding = {
  myGrails: unstarted,
};

const reducer: Reducer<Onboarding, Action> = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        myGrails: getStateAfterUserLoad(state.myGrails, action.payload),
      };

    // @ts-expect-error ts-migrate(2678) FIXME: Type '"ONBOARDING_MY_GRAILS_TOOLTIP_MOUNTED"' is n... Remove this comment to see the full error message
    case ONBOARDING_MY_GRAILS_TOOLTIP_MOUNTED:
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'payload' does not exist on type 'never'.
      return { ...state, myGrails: action.payload };

    case FOLLOW_CLICKED:
    case LISTING_TOOLTIP_CLICKED:
      return {
        ...state,
        myGrails:
          state.myGrails === started ? showHeaderTooltip : state.myGrails,
      };

    case HEADER_TOOLTIP_CLICKED:
      return { ...state, myGrails: completed };

    default:
      return state;
  }
};

export default reducer;
