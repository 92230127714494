import { Snapchat } from "custom-types/Snapchat";
import { UserSelf } from "../User";
import isInternalRoute from "../isInternalRoute";
import { Event } from "./Event";
import { Tracker } from "./Tracker";
import Service from "./Service";
import { isKnownBot } from "./isKnownBot";
import Properties from "./Properties";
import QueueTracker from "./QueueTracker";

/**
 * @name Analytics.SnapchatTracker
 * @private
 * @description A type signature for the Snapchat Dynamic Ads
 * https://businesshelp.snapchat.com/s/article/pixel-website-install?language=en_US
 */
export class SnapchatService implements Service {
  snaptr: Snapchat;

  readonly accountId = "2802c633-2ae5-463b-bf68-5c58308dd020";

  loaded = false;

  constructor(win: typeof window = window) {
    this.snaptr = win.snaptr;
  }

  ready = (onReady: () => void) => {
    onReady();
  };

  identify = (
    user?: UserSelf,
    _properties: Properties = {},
    onIdentified: () => void = () => {},
  ) => {
    if (isKnownBot()) return undefined;

    if (!this.loaded) {
      if (user) {
        this.snaptr("init", this.accountId, { user_email: user.email });
      } else {
        this.snaptr("init", this.accountId);
      }
      this.loaded = true;
    }

    onIdentified();
    return user;
  };

  track = (event: Event): Event => {
    if (isInternalRoute()) return event;
    if (isKnownBot()) return event;

    if (event.object === "confirmation_page" && event.action === "viewed") {
      this.snaptr("track", "PURCHASE", {
        price: event.properties.soldPrice,
        transaction_id: event.properties.listingId,
        item_ids: [event.properties.productId],
      });
    } else if (event.object === "page" && event.action === "viewed") {
      this.snaptr("track", "PAGE_VIEW");
    } else if (
      (event.object === "sign_up_step" && event.action === "completed") ||
      (event.object === "google_sign_up" && event.action === "completed") ||
      (event.object === "facebook_sign_up" && event.action === "completed") ||
      (event.object === "apple_sign_up" && event.action === "completed")
    ) {
      this.snaptr("track", "SIGN_UP");
    } else if (event.object === "product" && event.action === "viewed") {
      this.snaptr("track", "VIEW_CONTENT", {
        item_ids: [event.properties.product_id],
        item_category: event.properties.category,
      });
    }

    return event;
  };
}

let instance: Tracker | undefined;
export const getInstance = (): Tracker => {
  if (!instance) instance = new QueueTracker(new SnapchatService());
  return instance;
};
