import { reject, takeLast } from "lodash/fp";
import { FlashMessage } from "./index";

const MAX_MESSAGES = 5;

class MessagesStore {
  messages: Array<FlashMessage> = [];

  private changeSubscribers: Set<(messages: Array<FlashMessage>) => void> =
    new Set();

  add = (message: FlashMessage) => {
    this.change(takeLast(MAX_MESSAGES, [...this.messages, message]));
  };

  remove = (id: string) => {
    this.change(reject({ id }, this.messages));
  };

  clear = () => {
    this.change([]);
  };

  private change = (messages: Array<FlashMessage>) => {
    this.messages = messages;
    this.changeSubscribers.forEach((subscriber) => subscriber(this.messages));
  };

  onChange = (
    subscriber: (messages: Array<FlashMessage>) => void,
  ): (() => void) => {
    this.changeSubscribers.add(subscriber);

    return () => {
      this.changeSubscribers.delete(subscriber);
    };
  };
}

export default MessagesStore;
