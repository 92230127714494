import { UserSelf } from "../User";
import isInternalRoute from "../isInternalRoute";
import { CheckoutSuccessProduct, Event } from "./Event";
import { Tracker } from "./Tracker";
import Service from "./Service";
import { isKnownBot } from "./isKnownBot";
import Properties from "./Properties";
import QueueTracker from "./QueueTracker";

/**
 * @name Analytics.PinterestTracker.PinterestService
 * @private
 * @description A type signature for the Pinterest Ads library.
 */
export class PinterestService implements Service {
  pintrk: Pinterest;

  readonly accountId = 2614254847370;

  loaded = false;

  /**
   * @description The Pinterest tag script is loaded by third_party_js/_pinterest.html.erb or Page/DocumentHead.tsx.
   */
  constructor(win: typeof window = window) {
    this.pintrk = win.pintrk;
  }

  ready = (onReady: () => void) => {
    onReady();
  };

  identify = (
    user?: UserSelf,
    _properties: Properties = {},
    onIdentified: () => void = () => {},
  ) => {
    if (isKnownBot()) return undefined;

    if (!this.loaded) {
      if (user) {
        this.pintrk("load", this.accountId, { em: user.email });
      } else {
        this.pintrk("load", this.accountId);
      }
      this.loaded = true;
    }

    onIdentified();
    return user;
  };

  track = (event: Event): Event => {
    if (isInternalRoute()) return event;
    if (isKnownBot()) return event;

    if (event.object === "confirmation_page" && event.action === "viewed") {
      this.pintrk("track", "checkout", {
        value: event.properties.soldPrice,
        order_quantity: event.properties.quantity,
        currency: event.properties.currency,
        event_id: `checkout_${event.properties.orderId}`,
        line_items: event.properties.products.map(
          (product: CheckoutSuccessProduct) => {
            return {
              product_id: product.product_id,
              product_name: product.name,
              product_price: product.price,
              product_quantity: product.quantity,
            };
          },
        ),
      });
    } else if (event.object === "product" && event.action === "viewed") {
      this.pintrk("track", "pagevisit", {
        product_id: event.properties.product_id,
      });
    } else if (event.object === "page" && event.action === "viewed") {
      this.pintrk("page");
    } else if (
      (event.object === "sign_up_step" && event.action === "completed") ||
      (event.object === "google_sign_up" && event.action === "completed") ||
      (event.object === "facebook_sign_up" && event.action === "completed") ||
      (event.object === "apple_sign_up" && event.action === "completed")
    ) {
      this.pintrk("track", "signup");
    }

    return event;
  };
}

let instance: Tracker | undefined;
export const getInstance = (): Tracker => {
  if (!instance) instance = new QueueTracker(new PinterestService());
  return instance;
};
