/**
 * @namespace Values
 * @memberof Shipping
 * @description These types are used on Listing Drafts, Listings, Sell Form, and maybe more.
 */
import type { Code } from "./Region/Code";

/**
 * @memberof Shipping.Values
 * @description This type represents a user's preference for shipping to a region—specifically, whether they will ship to the region and, if they will, how much they'll charge the buyer for shipping.
 */
export type Value = {
  amount: number;
  enabled: boolean;
};

/**
 * @memberof Shipping.Values
 * @description This type is often used by forms—e.g. the sell form, listing edit form, etc.—to represent the user's shipping preferences for a given listing.
 */
export type Values = Record<Code, Value>;
export const empty: Values = {
  us: {
    enabled: false,
    amount: 0,
  },
  ca: {
    enabled: false,
    amount: 0,
  },
  uk: {
    enabled: false,
    amount: 0,
  },
  eu: {
    enabled: false,
    amount: 0,
  },
  asia: {
    enabled: false,
    amount: 0,
  },
  au: {
    enabled: false,
    amount: 0,
  },
  other: {
    enabled: false,
    amount: 0,
  },
};
