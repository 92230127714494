import { Options } from "storefront/GrailedAPI/request/Options";
import { generateDataKey } from "storefront/hooks/data/useData";
import { setInitialServerData } from "storefront/InitialDataProvider";

export type FilterOptions<T extends unknown[]> = T extends []
  ? []
  : T extends [infer H, ...infer R]
  ? H extends Options
    ? FilterOptions<R>
    : [H, ...FilterOptions<R>]
  : T;

class Fetcher<Response, FetcherResult, FetcherArgs extends unknown[] = []> {
  key?: string;

  unpack: (res: Response) => FetcherResult;

  fetch: (...args: FetcherArgs) => Promise<Response>;

  constructor(
    unpack: (res: Response) => FetcherResult,
    fetch: (...args: FetcherArgs) => Promise<Response>,
    key?: string,
  ) {
    if (!unpack) {
      throw new Error("Fetcher unpack function is required");
    }

    if (!fetch) {
      throw new Error("Fetcher fetch function is required");
    }

    this.key = key ?? fetch.name;
    this.unpack = unpack;
    this.fetch = fetch;
  }

  createDataKey = (...args: FilterOptions<FetcherArgs>): string => {
    return generateDataKey(this.fetch.name, ...args);
  };

  static isOption(arg: unknown | Options): arg is Options {
    if (!arg || typeof arg !== "object") {
      return false;
    }

    if (
      "body" in arg ||
      "headers" in arg ||
      "method" in arg ||
      "timeout" in arg ||
      "shouldSetInitialData" in arg ||
      "addPathHeader" in arg ||
      "addRiskifiedSessionId" in arg ||
      "addAuthHeader" in arg ||
      "version" in arg
    ) {
      return true;
    }

    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  argsWithoutOptions(args: FetcherArgs): FilterOptions<FetcherArgs> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return args.filter(
      (arg) => !Fetcher.isOption(arg),
    ) as FilterOptions<FetcherArgs>;
  }

  call = async (...args: FetcherArgs): Promise<FetcherResult> => {
    const response = await this.fetch(...args);
    const unpacked = this.unpack(response);
    const options = args.find(Fetcher.isOption) || {};
    const argsWithoutOptions = this.argsWithoutOptions(args);
    if (options.shouldSetInitialServerData && typeof window === "undefined") {
      setInitialServerData(this.createDataKey(...argsWithoutOptions), unpacked);
    }
    return unpacked;
  };
}

export default Fetcher;
