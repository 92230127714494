/**
 * @namespace Function
 * @description A collection of functions to be used on functions.
 */

/**
 * @name compose
 * @memberof Function
 * @description A properly-typed function composition operation. Returns the composition of two
 * given functions; i.e. given `b -> c` and `a -> b`, returns a single function `a -> c`.
 *
 * We mention that this is properly-typed because the compose function provided by many third-party
 * libraries (lodash, redux, etc.) are not typed correctly using flow; they trade off strict typing
 * for flexibily, as they allow the caller to compose an array of functions, instead of just two.
 *
 * `:: (b -> c) -> (a -> b) -> a -> c`
 */
export function compose<A, B, C>(
  f: (arg0: B) => C,
): (arg0: (arg0: A) => B) => (arg0: A) => C {
  return function composeLayer1(g: (arg0: A) => B): (arg0: A) => C {
    return function composeLayer2(a: A): C {
      return f(g(a));
    };
  };
}

/**
 * @name compose3
 * @memberof Function
 * @description A properly-typed function composition operation, but this time for 3 functions.
 * Returns the composition of three given functions; i.e. given `c -> d`, `b -> c`, and `a -> b`,
 * returns a single function `a -> c`.
 *
 * We mention that this is properly-typed because the compose function provided by many third-party
 * libraries (lodash, redux, etc.) are not typed correctly using flow; they trade off strict typing
 * for flexibily, as they allow the caller to compose an array of functions, instead of just three.
 *
 * `:: (c -> d) -> (b -> c) -> (a -> b) -> a -> d`
 */
export function compose3<A, B, C, D>(
  f: (arg0: C) => D,
): (arg0: (arg0: B) => C) => (arg0: (arg0: A) => B) => (arg0: A) => D {
  return function compose3Layer1(
    g: (arg0: B) => C,
  ): (arg0: (arg0: A) => B) => (arg0: A) => D {
    return function compose3Layer2(h: (arg0: A) => B): (arg0: A) => D {
      return function compose3Layer3(a: A): D {
        return f(g(h(a)));
      };
    };
  };
}
