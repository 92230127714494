import { get } from "storefront/GrailedAPI/request";

export type FeatureStatus = Record<string, string>;

type Response = {
  data: {
    features: FeatureStatus;
  };
};

const unpack = (res: Response): FeatureStatus => res.data.features;

const getFeaturesStatuses = (): Promise<FeatureStatus> =>
  get<Response>("/api/segment/features").then(unpack);

export default getFeaturesStatuses;
