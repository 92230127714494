/**
 * One Trust Consent Categories
 */

export const PERFORMANCE = "C0002" as const;
export const FUNCTIONAL = "C0003" as const;
export const ADVERTISING = "C0004" as const;
export const SOCIAL_MEDIA = "C0005" as const;

/**
 * Necessary for the app to function or to prevent fraud.
 * We never let a user disable this consent category!! - defining here for context.
 */
const STRICTLY_NECESSARY = "C0001" as const; // eslint-disable-line @typescript-eslint/no-unused-vars
