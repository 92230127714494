const getCookie = (_cookie: string, _cookies?: string) => {
  const cookie: Record<string, string> = {};

  const cookies = _cookies || window.document.cookie;

  cookies.split(";").forEach((c: string) => {
    const [key, value] = c.split("=");
    cookie[key.trim()] = value;
  });

  // eslint-disable-next-line no-underscore-dangle
  return cookie[_cookie] || undefined;
};

export default getCookie;
