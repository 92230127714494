import { get } from "../../request";

const empty = {
  buying: {
    unreadCount: 0,
  },
  selling: {
    unreadCount: 0,
  },
};
export type UnreadCounts = {
  buying: {
    unreadCount: number;
  };
  selling: {
    unreadCount: number;
  };
};

/**
 * @name GrailedAPI.v1.Conversations.getUnreadCounts
 * @return {Promise<Array<UnreadCounts>>} a promise that resolves to
 * something
 */
const getUnreadCounts = (): Promise<UnreadCounts> =>
  get(`/api/conversations/unread_counts`)
    .then(({ data }) => data)
    .catch((_error) => empty);

export default getUnreadCounts;
