export const FOLLOW_CLICKED: "ListingPage.Likes.FOLLOW_CLICKED" =
  "ListingPage.Likes.FOLLOW_CLICKED";
export const FOLLOW_RESPONDED: "ListingPage.Likes.FOLLOW_RESPONDED" =
  "ListingPage.Likes.FOLLOW_RESPONDED";
export const FOLLOW_ERRORED: "ListingPage.Likes.FOLLOW_ERRORED" =
  "ListingPage.Likes.FOLLOW_ERRORED";
export const UNFOLLOW_CLICKED: "ListingPage.Likes.UNFOLLOW_CLICKED" =
  "ListingPage.Likes.UNFOLLOW_CLICKED";
export const UNFOLLOW_RESPONDED: "ListingPage.Likes.UNFOLLOW_RESPONDED" =
  "ListingPage.Likes.UNFOLLOW_RESPONDED";
export const UNFOLLOW_ERRORED: "ListingPage.Likes.UNFOLLOW_ERRORED" =
  "ListingPage.Likes.UNFOLLOW_ERRORED";
