/* eslint-disable import/prefer-default-export */

/**
 * @namespace Conversations
 */
import { Context, empty as emptyContext } from "./Context";

/**
 * @name Conversations
 * @memberof Conversations
 * @description This type represents the Conversations slice of the Global Redux Store.
 */
export type Conversations = {
  context: "buying" | "selling";
  isViewingArchived: boolean;
  offerModalIsOpen: boolean;
  offerModalConversationId: string | null | undefined;
  replyModalIsOpen: boolean;
  replyModalConversationId: string | null | undefined;
  individualLoadingConversations: Array<number>;
  buying: Context;
  selling: Context;
};

export const empty: Conversations = {
  context: "buying",
  isViewingArchived: false,
  offerModalIsOpen: false,
  offerModalConversationId: null,
  replyModalIsOpen: false,
  replyModalConversationId: null,
  individualLoadingConversations: [],
  buying: emptyContext,
  selling: emptyContext,
};

/**
 * @name totalUnread
 * @memberof Conversations
 * @description Adds the unread count of the buying and selling contexts.
 *
 * ```
 * totalUnread :: Conversations => number
 * ```
 */
export const totalUnread = ({ buying, selling }: Conversations): number =>
  buying.unreadCount + selling.unreadCount;

/**
 * @name hasUnread
 * @memberof Conversations
 * @description Checks the buying and selling contexts to see if either has an unread count.
 *
 * ```
 * hasUnread :: Conversations => boolean
 * ```
 */
export const hasUnread = ({ buying, selling }: Conversations): boolean =>
  !!buying.unreadCount || !!selling.unreadCount;
