import { propOr } from "lodash/fp";
import { StartAuthenticateUserEvent } from "storefront/components/Authentication/AuthenticateUser/StartAuthenticateUserEvent";
import { CompleteAuthenticateUserEvent } from "storefront/components/Authentication/AuthenticateUser/CompleteAuthenticateUserEvent";
import { StartVerifyEmailEvent } from "storefront/components/Authentication/VerifyEmail/StartVerifyEmailEvent";
import { CompleteIdentityProviderEvent } from "storefront/components/Authentication/AuthenticateUser/IdentityProvider/CompleteIdentityProviderEvent";
import { FailIdentityProviderEvent } from "storefront/components/Authentication/AuthenticateUser/IdentityProvider/FailIdentityProviderEvent";
import { SellerBadgeTappedEvent } from "storefront/Analytics/EventCreators/sellerBadgeTapped";
import { SignUpStepCompletedEvent } from "storefront/components/Authentication/SignUpMultiStep/SignUpEmail";
import { IdentityProviderSignUpCompletedEvent } from "storefront/components/Authentication/SignUpMultiStep/SignUpOptions";
import { AccountModalOpenedEvent } from "storefront/components/Authentication/authWrapper/accountModalOpenedEventCreator";
import { CommentPostedEvent } from "storefront/components/Comments";
import { ManageSavedSearchesStartedEvent } from "storefront/components/FiltersInstantSearch/SavedSearches/SavedSearches";
import { KeywordViewedEvent } from "storefront/components/KeywordPage/keywordViewedEvent";
import { RelistRequestStartedEvent } from "storefront/components/ListingPage/Actions/RequestRepostWithAmountAction";
import { ListingDeletedEvent } from "storefront/Analytics/EventCreators/listingDeletedEvent";
import { ProductViewedEvent } from "storefront/components/ListingPage/productViewedEvent";
import { ModuleViewedEvent } from "storefront/Analytics/EventCreators/moduleViewed";
import { FeatureBlockViewedEvent } from "storefront/components/Modules/FeaturedContentModule/FeaturedBlock";
import { SearchSuggestionClickedEvent } from "storefront/components/Page/Header/AlgoliaAutocomplete/autocompleteSources";
import { ArticleClickedEvent } from "storefront/Analytics/EventCreators/articleClicked";
import { ConfirmationPageViewed } from "storefront/Analytics/EventCreators/confirmationPageViewed";
import { SellFlowStartedEvent } from "storefront/Analytics/EventCreators/sellFlowStarted";
import { SSLTButtonClickedEvent } from "storefront/Analytics/EventCreators/ssltButtonClicked";
import { OrderStartedEvent } from "storefront/Analytics/EventCreators/orderStarted";
import { SearchFilterChangedEvent } from "storefront/Analytics/EventCreators/searchFilterChanged";
import { SearchBarClickedEvent } from "storefront/Analytics/EventCreators/searchBarClicked";
import { SavedSizesEditedEvent } from "storefront/Analytics/EventCreators/savedSizesEdited";
import { SavedSizesToggledEvent } from "storefront/Analytics/EventCreators/savedSizesToggled";
import { SavedSizesSetUpEvent } from "storefront/Analytics/EventCreators/savedSizesSetUp";
import { SellerHubViewedEvent } from "storefront/Analytics/EventCreators/sellerHubViewed";
import { Department } from "storefront/Department";
import { SimilarListingsFeedViewedEvent } from "storefront/Analytics/EventCreators/similarListingsFeedViewed";
import { MessageStartedEvent } from "storefront/Analytics/EventCreators/messageStarted";
import { TransactionDetailViewedEvent } from "storefront/components/TransactionDetailsPage/events/transactionDetailViewedEvent";
import { UserProfileButtonClickedEvent } from "storefront/components/TransactionDetailsPage/events/userProfileButtonClicked";
import { GrailedLabelManagementClickedEvent } from "storefront/components/SoldTransactionDetailsPage/events/grailedLabelManagementClicked";
import { SellerProvidedShippingManagementClickedEvent } from "storefront/components/SoldTransactionDetailsPage/events/sellerProvidedShippingManagementClicked";
import { ContactSupportClickedEvent } from "storefront/components/SoldTransactionDetailsPage/events/contactSupportClicked";
import { FeedbackButtonClicked } from "storefront/components/PurchasedTransactionDetailsPage/events/feedbackButtonClicked";
import { ViewTrackingButtonClickedEvent } from "storefront/components/PurchasedTransactionDetailsPage/events/viewTrackingButtonClicked";
import { BannerViewedEvent } from "storefront/Analytics/EventCreators/bannerViewed";
import { BannerClickedEvent } from "storefront/Analytics/EventCreators/bannerClicked";
import { DataZoneCollectionViewedEvent } from "./EventCreators/dataZoneCollectionViewed";
import { ListingDuplicatedEvent } from "./EventCreators/listingDuplicated";
import { IndexedList } from "../lib/IndexedList";
import { Id } from "../lib/Id";
import { Values as ShippingValues } from "../Shipping/Values";
import { Algolia$Filters } from "../Algolia";
import titleCase from "../lib/String/titleCase";
import nameToListingIndex from "../Algolia/nameToListingIndex";
import { AddEditAddressSelectedEvent } from "./EventCreators/addEditAddressSelected";
import { AddressRemovedEvent } from "./EventCreators/addressRemoved";
import { AddressSubmittedEvent } from "./EventCreators/addressSubmitted";
import { AddressVerificationPerformedEvent } from "./EventCreators/addressVerificationPerformed";
import { ArticleViewedEvent } from "./EventCreators/articleViewed";
import { AuthenticationStartedEvent } from "./EventCreators/authenticationStarted";
import { ButtonClickedEvent } from "./EventCreators/buttonClicked";
import { CheckoutStartedEvent } from "./EventCreators/checkoutStarted";
import { CTATappedEvent } from "./EventCreators/ctaTapped";
import { DesignerCategoryDetailPageViewedEvent } from "./EventCreators/designerCategoryDetailPageViewed";
import { DesignerDetailPageViewedEvent } from "./EventCreators/designerDetailPageViewed";
import { EnterGiveawaySelectedEvent } from "./EventCreators/enterGiveawaySelected";
import { FilterCreatedEvent } from "./EventCreators/filterCreated";
import { FilterDeletedEvent } from "./EventCreators/filterDeleted";
import { ForSaleFilteredEvent } from "./EventCreators/forSaleFiltered";
import { GetQrCodeTappedEvent } from "./EventCreators/getQrCodeTapped";
import { ModuleClickedEvent } from "./EventCreators/moduleClicked";
import { NavigationClickedEvent } from "./EventCreators/navigationClicked";
import { OfferStartedEvent } from "./EventCreators/offerStarted";
import { OfferStepCompletedEvent } from "./EventCreators/offerStepCompleted";
import { PageViewedEvent } from "./EventCreators/pageView";
import { PayPalButtonClickedEvent } from "./EventCreators/payPalButtonClicked";
import { ProductAddedToWishlistEvent } from "./EventCreators/productAddedToWishlist";
import { RemoveFromFavoritesEvent } from "./EventCreators/removeFromFavorites";
import { PrintShippingLabelTappedEvent } from "./EventCreators/printShippingLabelTapped";
import { ProductListFilteredEvent } from "./EventCreators/productListFiltered";
import { ProductListSortedEvent } from "./EventCreators/productListSorted";
import { ProductListViewedEvent } from "./EventCreators/productListViewed";
import { ProductSearchedEvent } from "./EventCreators/productSearched";
import { PhotoInteractionEvent } from "./EventCreators/photoInteraction";
import { RemoveAddressSelectedEvent } from "./EventCreators/removeAddressSelected";
import { RepostButtonClickedEvent } from "./EventCreators/repostButtonClicked";
import { SellFlowCompletedEvent } from "./EventCreators/sellFlowCompleted";
import { SellerInitiatedOfferPriceErrorEvent } from "./EventCreators/sellerInitiatedOfferPriceError";
import { SuperskuClickedEvent } from "./EventCreators/superSkuClicked";
import { CollectionFollowEvent } from "./EventCreators/toggleCollectionFollow";
import { DesignerFollowEvent } from "./EventCreators/toggleDesignerFollow";
import { UserFollowEvent } from "./EventCreators/toggleUserFollow";
import { MicrositeViewedEvent } from "./EventCreators/trustMicrositeViewed";
import { UserProfileViewedEvent } from "./EventCreators/userProfileViewed";
import { GrailedPaymentsStarted } from "./EventCreators/GrailedPaymentsOnboarding";
import { DesignerKeywordDetailPageViewedEvent } from "./EventCreators/designerKeywordDetailPageViewed";
import { DesignerDepartmentCategoryDetailPageViewedEvent } from "./EventCreators/designerDepartmentCategoryDetailPageViewed";
import {
  UserRefundStartedEvent,
  UserRefundStepCompletedEvent,
} from "./EventCreators/userRefund";
import { ListingDetailsEvent } from "./EventCreators/listingDetails";
import { PaymentGatewaySelectedEvent } from "./EventCreators/paymentGatewaySelected";
import { ExtendShippingButtonClickedEvent } from "./EventCreators/extendShippingClicked";
import { GPOnboardingPageViewedEvent } from "./EventCreators/gpOnboardingPageViewed";
import { GPOnboardingSkippedEvent } from "./EventCreators/gpOnboardingSkipped";
import { AllPaymentMethodsClickedEvent } from "./EventCreators/allPaymentMethodsClicked";

export type { IdentityProvider } from "../GrailedAPI/v1/Sessions/create";

export type PageType =
  | "article"
  | "category"
  | "conversation"
  | "collections"
  | "datazone_collection"
  | "designer"
  | "designer_category"
  | "designer_collaboration"
  | "designer_keyword"
  | "designer_department_category"
  | "favorites"
  | "followed_designers"
  | "followed_sellers"
  | "for_you"
  | "homepage"
  | "keyword"
  | "listing"
  | "my_for_sale"
  | "my_sizes"
  | "saved_searches"
  | "search_feed"
  | "seller_profile"
  | "similar_listings_feed"
  | "sold"
  | "user_settings"
  | "my_sales"
  | "closet"
  | "saved_listings";

export type ProductClickedFrom = {
  component?: string;
  moduleName?: string;
  moduleType?: string;
  pageType?: PageType;
  pageTypeName?: string;
  pageTypeIdentifier?: Id | null;
  searchText?: string;
  sort?: string;
};

/**
 * Where they clicked to filter the feed. Note that we *do not* track filters from search_input.
 * That is tracked in the Product Searched event.
 */
export type ProductListFilteredFrom =
  | "feed_sidebar"
  | "curated_list"
  | "saved_search"
  | "subheader"
  | "search_input"
  | "module";

export type FiltersRaw = {
  query?: string;
  uuid: string;
  filters: Algolia$Filters;
  indexName: string;
};

export type CheckoutSuccessProduct = {
  product_id: Id;
  name: string;
  quantity: number;
  price: number;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'PropertyValue' circularly references i... Remove this comment to see the full error message
export type PropertyValue =
  | string
  | number
  | null
  | void
  | boolean
  | Array<Id>
  | Array<Product> // eslint-disable-line no-use-before-define
  | Array<string>
  | IndexedList<PropertyValue>
  | Array<CheckoutSuccessProduct>
  | ShippingValues
  | Array<{
      id: Id;
      name: string;
      slug: string;
    }>
  | {
      labelType: string | null | undefined;
    };

export type Product = {
  badges: Array<string>;
  brand: string;
  category: string;
  department: Department;
  name: string;
  product_id: Id;
  size: string;
  sold: boolean;
  strata: string;
  supersku_id: Id | null | undefined;
  tags: Array<string> | null;
  value: number;
  quantity?: number;
  price?: number;
};

export type Filter = {
  badges: Array<string>;
  brands: Array<string>;
  categories: Array<string>;
  list_id?: string;
  max_price: number | null;
  min_price: number | null;
  search: string;
  sizes: string | Array<string>;
  sort: string;
  strata: string | Array<string>;
  condition?: string | Array<string>;
  departments: Array<Department>;
};

type WishlistViewedEvent = {
  object: "wishlist";
  action: "viewed";
  properties: {
    nonInteraction: 1;
  };
};

export type RelatedSearchClickedEvent = {
  object: "related search";
  action: "clicked";
  properties: {
    detailPageId: Id;
    title: string;
    listingId: Id;
    pageType: PageType;
    itemPosition: number;
  };
};

type DraftCreatedEvent = {
  object: "draft";
  action: "created";
  properties: {
    from: string;
    draftId: Id;
    itemName: string | null | undefined;
    categoryPath: string | null | undefined;
    department: Department | null | undefined;
    size: string | null | undefined;
    price: number | null | undefined;
    floorPrice: number | null | undefined;
    smartPricingEnabled: boolean;
    makeOffer: boolean;
    shippingCost: ShippingValues;
    designers: Array<{
      id: Id;
      name: string;
      slug: string;
    }>;
    color: string;
    sneakerIdNumber: string;
    productId: Id | null | undefined;
    hasMeasurements: boolean;
    percentile25: number | null;
    percentile40: number | null;
    median: number | null;
    percentile60: number | null;
    percentile75: number | null;
    recommendationTitle: string | null;
    priceRecommendationType: string | null;
    priceRecommendationLookbackWindow: string | null;
    shippingLabel: {
      labelType: string | null | undefined;
    };
    eligibleForShippingLabels: boolean;
  };
};

type ProductCreatedEvent = {
  object: "product";
  action: "created";
  properties: {
    from: string;
    listingId: Id;
    itemName: string | null | undefined;
    categoryPath: string | null | undefined;
    department: Department | null | undefined;
    size: string | null | undefined;
    price: number | null | undefined;
    floorPrice: number | null | undefined;
    smartPricingEnabled: boolean;
    makeOffer: boolean;
    shippingCost: ShippingValues;
    designers: Array<{
      id: Id;
      name: string;
      slug: string;
    }>;
    color: string;
    sneakerIdNumber: string;
    productId: Id | null | undefined;
    hasMeasurements: boolean;
    percentile25: number | null;
    percentile40: number | null;
    median: number | null;
    percentile60: number | null;
    percentile75: number | null;
    recommendationTitle: string | null;
    priceRecommendationType: string | null;
    priceRecommendationLookbackWindow: string | null;
    shippingLabel: {
      labelType: string | null | undefined;
    };
    eligibleForShippingLabels: boolean;
  };
};

type FollowedDesignersViewedEvent = {
  object: "followed designers";
  action: "viewed";
};

type ProductClickedEvent = {
  object: "product";
  action: "clicked";
  properties: ProductClickedFrom &
    Product & {
      from?: string;
      algoliaQueryId?: string | null;
      position?: number | null;
      defaultAlgoliaIndex?: string | null;
      algoliaAbTestId?: string | null;
      algoliaAbTestVariantId?: string | null;
    };
};

type HomepageViewedEvent = {
  object: "homepage";
  action: "viewed";
  properties: {
    nonInteraction: 1;
  };
};

type ForYouViewedEvent = {
  object: "for_you";
  action: "viewed";
  properties: {
    user_id: Id;
  };
};

type CategoryViewedEvent = {
  object: "category";
  action: "viewed";
  properties: {
    page_id?: Id;
    hero_url?: string;
    slug?: string;
    title?: string;
    type?: string;
    category_name?: string;
    department: Department;
    nonInteraction: 1;
  };
};

type CollectionViewedEvent = {
  object: "collection";
  action: "viewed";
  properties: {
    page_id?: Id;
    hero_url?: string;
    slug?: string;
    title?: string;
    type?: string;
    capsule_id?: Id;
    capsule_name?: string;
    capsule_listing_ids?: Array<Id>;
    capsule_published_at?: string;
    nonInteraction: 1;
  };
};

/**
 * @name Analytics.Event
 * @description A type signature for a trackable event in the Grailed domain. Is consumed by each
 * tracker instance and used to execute the underlying tracker logic (GA, Segment, etc.).
 */
export type Event =
  | AccountModalOpenedEvent
  | AddEditAddressSelectedEvent
  | AddressRemovedEvent
  | AddressSubmittedEvent
  | AddressVerificationPerformedEvent
  | ArticleClickedEvent
  | ArticleViewedEvent
  | StartAuthenticateUserEvent
  | CompleteAuthenticateUserEvent
  | StartVerifyEmailEvent
  | AuthenticationStartedEvent
  | ButtonClickedEvent
  | CategoryViewedEvent
  | CheckoutStartedEvent
  | CollectionFollowEvent
  | CollectionViewedEvent
  | CommentPostedEvent
  | CompleteIdentityProviderEvent
  | ConfirmationPageViewed
  | CTATappedEvent
  | DesignerCategoryDetailPageViewedEvent
  | DesignerDetailPageViewedEvent
  | DesignerFollowEvent
  | DraftCreatedEvent
  | EnterGiveawaySelectedEvent
  | FailIdentityProviderEvent
  | FeatureBlockViewedEvent
  | FilterCreatedEvent
  | FilterDeletedEvent
  | FollowedDesignersViewedEvent
  | ForSaleFilteredEvent
  | ForYouViewedEvent
  | HomepageViewedEvent
  | IdentityProviderSignUpCompletedEvent
  | KeywordViewedEvent
  | ListingDeletedEvent
  | ListingDetailsEvent
  | ListingDuplicatedEvent
  | ManageSavedSearchesStartedEvent
  | MessageStartedEvent
  | MicrositeViewedEvent
  | ModuleClickedEvent
  | ModuleViewedEvent
  | NavigationClickedEvent
  | OfferStartedEvent
  | OfferStepCompletedEvent
  | OrderStartedEvent
  | PageViewedEvent
  | PaymentGatewaySelectedEvent
  | PayPalButtonClickedEvent
  | ProductAddedToWishlistEvent
  | RemoveFromFavoritesEvent
  | PrintShippingLabelTappedEvent
  | ProductClickedEvent
  | ProductCreatedEvent
  | ProductListFilteredEvent
  | ProductListSortedEvent
  | ProductListViewedEvent
  | ProductSearchedEvent
  | PhotoInteractionEvent
  | ProductViewedEvent
  | RelistRequestStartedEvent
  | RemoveAddressSelectedEvent
  | RepostButtonClickedEvent
  | SavedSizesEditedEvent
  | SavedSizesSetUpEvent
  | SavedSizesToggledEvent
  | SearchBarClickedEvent
  | SearchFilterChangedEvent
  | SearchSuggestionClickedEvent
  | SellerBadgeTappedEvent
  | SellerHubViewedEvent
  | SellerInitiatedOfferPriceErrorEvent
  | SellFlowCompletedEvent
  | SellFlowStartedEvent
  | SignUpStepCompletedEvent
  | SimilarListingsFeedViewedEvent
  | SSLTButtonClickedEvent
  | SuperskuClickedEvent
  | UserFollowEvent
  | UserProfileViewedEvent
  | UserRefundStartedEvent
  | UserRefundStepCompletedEvent
  | WishlistViewedEvent
  | RelatedSearchClickedEvent
  | GrailedPaymentsStarted
  | DesignerKeywordDetailPageViewedEvent
  | DesignerDepartmentCategoryDetailPageViewedEvent
  | TransactionDetailViewedEvent
  | UserProfileButtonClickedEvent
  | GrailedLabelManagementClickedEvent
  | SellerProvidedShippingManagementClickedEvent
  | ContactSupportClickedEvent
  | GetQrCodeTappedEvent
  | FeedbackButtonClicked
  | ExtendShippingButtonClickedEvent
  | ViewTrackingButtonClickedEvent
  | GPOnboardingPageViewedEvent
  | GPOnboardingSkippedEvent
  | AllPaymentMethodsClickedEvent
  | BannerViewedEvent
  | BannerClickedEvent
  | DataZoneCollectionViewedEvent;

/**
 * @name Analytics.Event.EVENT_NAME_SEPARATOR
 * @description Word separator for analytics event names
 */
const EVENT_NAME_SEPARATOR = "_";

/**
 * @name Analytics.Event.getName
 * @description Returns the name of an event by combining the object and the action.
 */
export const getName: (arg0: Event) => string = ({ object, action }) =>
  `${titleCase(object, EVENT_NAME_SEPARATOR)} ${titleCase(
    action,
    EVENT_NAME_SEPARATOR,
  )}`;

const grabDesignerIds: (arg0: FiltersRaw) => Array<string> = (filters) =>
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  propOr([], "filters.designers", filters).map((id) => id.toString());

/**
 * @name Analytics.Event.getFilterPropertiesFromData
 * @description Given data from GrailedAPI.savedSearches.create,
 * returns a formatted object for a product list-related event
 */
export const getFilterPropertiesFromData = (filters: FiltersRaw): Filter => ({
  badges: propOr([], "filters.badges", filters),
  brands: grabDesignerIds(filters),
  categories: propOr([], "filters.categoryPaths", filters),
  departments: filters?.filters?.department ?? [],

  /* eslint-disable camelcase */
  list_id: propOr("", "uuid", filters),
  max_price: propOr(null, "filters.maxPrice", filters),
  min_price: propOr(null, "filters.minPrice", filters),

  /* eslint-enable camelcase */
  search: propOr("", "query", filters),
  sizes: propOr([], "filters.sizes", filters),
  sort: nameToListingIndex(propOr("", "indexName", filters)),
  strata: propOr([], "filters.strata", filters),
});
