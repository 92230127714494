import { compose, join, lowerCase, map, split, startCase } from "lodash/fp";

const titleCase = (textString: string, splitToken?: string | null): string =>
  compose([
    startCase,
    join(" "),
    map(startCase),
    map(lowerCase),
    split(splitToken || " "),
  ])(textString);

export default titleCase;
