import { get } from "storefront/GrailedAPI/request";
import { Id } from "storefront/lib/Id";

type Response = {
  data: Array<{ id: Id }>;
};

const unpack = (res: Response): Array<{ id: Id }> => res.data;

const followedListings = (): Promise<Array<{ id: Id }>> =>
  get<Response>("/api/users/followed_listings").then(unpack);

export default followedListings;
