import { chunk, last, pipe } from "lodash/fp";
import algoliaInsights from "search-insights";
import { getVisitorId } from "storefront/lib/VisitorId";
import { UserSelf } from "storefront/User";
import { Event } from "./Event";
import { Tracker } from "./Tracker";
import { isKnownBot } from "./isKnownBot";
import Service from "./Service";
import QueueTracker from "./QueueTracker";
import Properties from "./Properties";

export class AlgoliaInsightsService implements Service {
  appId?: string;

  apiKey?: string;

  constructor(appId?: string, apiKey?: string) {
    this.appId = appId;
    this.apiKey = apiKey;
  }

  ready = (onReady: () => void) => {
    if (this.appId && this.apiKey) {
      algoliaInsights("init", {
        appId: this.appId,
        apiKey: this.apiKey,
      });

      onReady();
    }
  };

  track = (event: Event): Event => {
    if (isKnownBot()) return event;

    switch (event.object) {
      case "product_list":
        switch (event.action) {
          case "viewed": {
            const { listingIds } = event.properties;
            const lastTrancheOfObjectIds = pipe(chunk(40), last)(listingIds);
            chunk(20)(lastTrancheOfObjectIds).forEach((idsGroup) => {
              algoliaInsights("viewedObjectIDs", {
                eventName: "Product List Viewed",
                objectIDs: idsGroup.map((id) => `${id}`),
                index: event.properties.index,
              });
            });
            return event;
          }

          default:
            return event;
        }

      case "search_filter":
        switch (event.action) {
          case "changed": {
            const { algoliaIndex, filters } = event.properties;

            if (filters.length === 0) return event;

            algoliaInsights("clickedFilters", {
              index: algoliaIndex,
              eventName: "Search Filter Changed",
              filters,
            });
            return event;
          }

          default:
            return event;
        }

      case "product":
        switch (event.action) {
          case "clicked": {
            /* eslint-disable camelcase */
            const {
              algoliaQueryId,
              defaultAlgoliaIndex,
              product_id,
              position,
              sort,
            } = event.properties;
            const eventName = "Product Clicked";
            const objectIDs = [`${product_id}`];

            if (algoliaQueryId && sort && position) {
              algoliaInsights("clickedObjectIDsAfterSearch", {
                index: sort,
                eventName,
                queryID: algoliaQueryId,
                objectIDs,
                positions: [position],
              });
            } else if (defaultAlgoliaIndex) {
              algoliaInsights("clickedObjectIDs", {
                index: defaultAlgoliaIndex,
                eventName,
                objectIDs,
              });
            }

            return event;
            /* eslint-enable camelcase */
          }

          default:
            return event;
        }

      case "offer_step": {
        switch (event.action) {
          case "completed": {
            /* eslint-disable camelcase */
            const {
              step,
              algoliaQueryId,
              algoliaIndex,
              is_binding,
              listing_id,
            } = event.properties;
            const objectIDs = [`${listing_id}`];
            if (!algoliaIndex) return event;

            switch (step) {
              case "offer_detail": {
                // Only fire Offer Sent on this step if non-binding offer
                if (is_binding) return event;

                if (algoliaQueryId) {
                  algoliaInsights("convertedObjectIDsAfterSearch", {
                    index: algoliaIndex,
                    eventName: "Offer Sent",
                    queryID: algoliaQueryId,
                    objectIDs,
                  });
                } else {
                  algoliaInsights("convertedObjectIDs", {
                    index: algoliaIndex,
                    eventName: "Offer Sent",
                    objectIDs,
                  });
                }

                return event;
              }

              case "offer_review": {
                // Only fire Offer Sent on this step if binding offer
                if (!is_binding) return event;

                if (algoliaQueryId) {
                  algoliaInsights("convertedObjectIDsAfterSearch", {
                    index: algoliaIndex,
                    eventName: "Offer Sent",
                    queryID: algoliaQueryId,
                    objectIDs,
                  });
                } else {
                  algoliaInsights("convertedObjectIDs", {
                    index: algoliaIndex,
                    eventName: "Offer Sent",
                    objectIDs,
                  });
                }

                return event;
              }

              default:
                return event;
            }
            /* eslint-enable camelcase */
          }

          default:
            return event;
        }
      }

      case "order_started": {
        switch (event.action) {
          case "clicked": {
            const { algoliaIndex, algoliaQueryId, listingId } =
              event.properties;
            const objectIDs = [`${listingId}`];
            if (!algoliaIndex) return event;

            if (algoliaQueryId) {
              algoliaInsights("convertedObjectIDsAfterSearch", {
                index: algoliaIndex,
                eventName: "Order Started",
                queryID: algoliaQueryId,
                objectIDs,
              });
            } else {
              algoliaInsights("convertedObjectIDs", {
                index: algoliaIndex,
                eventName: "Order Started",
                objectIDs,
              });
            }

            return event;
          }

          default:
            return event;
        }
      }

      default:
        return event;
    }
  };

  identify = (
    user?: UserSelf,
    properties?: Properties,
    onIdentified: () => void = () => {},
  ) => {
    const token = user?.id || getVisitorId(window);
    algoliaInsights("setUserToken", `${token}`);
    onIdentified();
    return user;
  };
}

let instance: Tracker;
export const getInstance = (): Tracker => {
  if (!instance) {
    const appId =
      process.env.ALGOLIA_APPLICATION_ID ||
      process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID;
    const apiKey =
      process.env.ALGOLIA_PUBLIC_SEARCH_KEY ||
      process.env.NEXT_PUBLIC_ALGOLIA_PUBLIC_SEARCH_KEY;

    const service = new AlgoliaInsightsService(appId, apiKey);
    instance = new QueueTracker(service);
  }
  return instance;
};
