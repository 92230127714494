// All features that can be flipped on/off for a user
// This list serves several purposes:
// 1. Store feature values as constants for easy reference
// 2. Provide a list of all active/used features in the codebase
// 3. Make cleanup easy by throwing TS errors anywhere a feature is used that is no longer active
export enum Feature {
  CYBER_SALE_2024 = "cyber_sale_2024",
  NEW_HOMEPAGE_LOGGED_IN = "new_homepage_logged_in",
  NEW_HOMEPAGE_LOGGED_OUT = "new_homepage_logged_out",
  REMOVE_AUTH_BADGE = "remove_auth_badge",
  PAYMENTS_TOOLS = "payments_tools",
  DEFAULT_RETURN_ADDRESS = "default_return_address",
  STRIPE_ONLY_ONBOARDING = "stripe_only_onboarding",
  FILTERABLE_COLLECTIONS = "filterable_collections",
}
