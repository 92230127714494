/**
 * @namespace Resource
 * @description Resource is a data type intended to provide a useful structure around a value, of
 * some type (`t`), that is derived from some asyncronous operation; often an HTTP request, but
 * really anything that returns a promise.
 *
 * A Resource is a snapshot of one moment in time, it is not the async operation, just the possible
 * result; if you want to work with a potential future value of an async operation, you should
 * check out Promises!
 *
 * The type of the value can be anything, but the value is only available when the resource is in
 * the completed state. This structure makes accessing the value explict, as the code must deal
 * with all three potential states.
 *
 * Below, there are some helper functions to make working with this data type easier, as well as a
 * React Hook that can be used in place of managing component local state manually in every
 * component that needs to request data.
 */
export type Loading = {
  type: "Loading";
};
export type Completed<T> = {
  type: "Completed";
  value: T;
};
export type Failed = {
  type: "Failed";
  error: Error;
};
export type Resource<T> = Loading | Completed<T> | Failed;

/**
 * @name loading
 * @memberof Resource
 * @description A nullary type representing the loading state of a resource. All loading resources
 * are represented by this same exact instance, unlike the completed or failed states, because
 * there is no external value or error needed to construct this type.
 *
 * `:: Loading`
 */
export const loading: Loading = {
  type: "Loading",
};

/**
 * @name completed
 * @memberof Resource
 * @description A unary type representing the completed state of a resource, i.e. the state after
 * the getter promise has resolved. This type is a function because it needs some value, of type
 * `t` to be initialized.
 *
 * `:: t -> Completed t`
 */
export function completed<T>(value: T): Completed<T> {
  return {
    type: "Completed",
    value,
  };
}

/**
 * @name failed
 * @memberof Resource
 * @description A nullary type representing the failed state of a resource, i.e. the state after the
 * getter promise has rejected. This type is a function because it needs some error to be
 * initialized.
 *
 * `:: Error -> Failed`
 */
export function failed(error: Error): Failed {
  return {
    type: "Failed",
    error,
  };
}
