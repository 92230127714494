import { VerificationStatus } from "storefront/GrailedAPI/v1/PostalAddresses/verify";
import { Id, emptyId } from "storefront/lib/Id";

type Address = {
  recipientName?: string;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  countryCode?: string;
};

/* This type is mapped to postal addresses related API response.
 */
export type PostalAddress = Omit<
  Address,
  "recipientName" | "line1" | "countryCode"
> & {
  _type: "PostalAddress";
  id: Id;
  recipientName: string;
  line1: string;
  countryCode: string;
  nickname?: string;
  eligibleForGrailedLabel: boolean;
  verificationStatus: VerificationStatus | null | undefined;
  defaultReturn: boolean;
  deletedAt: string | null;
  phoneNumber: string | null;
  phoneNumberCountryCode: string | null;
};

export const emptyPostalAddress: PostalAddress = {
  _type: "PostalAddress",
  id: emptyId,
  recipientName: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postalCode: "",
  countryCode: "",
  eligibleForGrailedLabel: false,
  verificationStatus: null,
  defaultReturn: false,
  deletedAt: null,
  phoneNumber: null,
  phoneNumberCountryCode: null,
};

export type BillingAddress = Address & {
  _type: "BillingAddress";
};

export const emptyBillingAddress: BillingAddress = {
  _type: "BillingAddress",
  recipientName: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postalCode: "",
  countryCode: "",
};

export const isUSAddress = (address: Address | null | undefined): boolean =>
  address?.countryCode === "US";

export const isCAAddress = (address: Address | null | undefined): boolean =>
  address?.countryCode === "CA";

export const isAddressEligibleForGrailedLabels = (
  address: PostalAddress | null | undefined,
): boolean => address?.eligibleForGrailedLabel === true;

export const isDefaultReturnAddress = (address: PostalAddress): boolean =>
  address.defaultReturn === true;

// Matches PO Box regex used on the backend for the PostalAddress model
const PO_BOX_REGEX = /(?:Post(?:al)?\s(?:Office\s)?|P[.\s]?O\.?)\s?Box\b/i;

export const isPOBox = (address: PostalAddress): boolean =>
  PO_BOX_REGEX.test(address.line1);

/* ShippingAddress will eventually deprecate when we update these fields
 * returned to us in the User serializer to follow our new API format
 */
export type ShippingAddress = {
  country: string;
  city: string;
  state: string;
  zip: string;
  street1: string;
  street2: string;
  addresseeName: string;
};

export const empty: ShippingAddress = {
  country: "",
  city: "",
  state: "",
  zip: "",
  street1: "",
  street2: "",
  addresseeName: "",
};
