import { UserSelf } from "../User";
import { Event } from "./Event";
import Properties from "./Properties";

/**
 * @name Analytics.Tracker
 * @description Each tracker must implement a track method and an identify method. The track method
 * takes a Event and uses it to execute the service-specific logic to properly capture the
 * event. The identify method takes a User and executes the service-specific logic to let the
 * service know the identity of the current User.
 */
export interface Tracker {
  track: (event: Event) => Event;
  identify: (user?: UserSelf, properties?: Properties) => UserSelf | undefined;
}
export const empty: Tracker = {
  track: (event) => event,
  identify: (user) => user,
};
