import { some } from "lodash/fp";
// a list of bot identifiers, we want to avoid firing segment track events for bots
export const knownBots = [
  "Googlebot",
  "Applebot",
  "SpeedCurve",
  "Pinterestbot",
  "vintagio-bot",
  "PetalBot",
  "yandex.com/bots",
  "SemrushBot",
  "Twitterbot",
  "Storebot",
  "KlarnaBot",
  "WatcherBot",
];
export const isKnownBot = ({
  navigator: { userAgent },
}: typeof window = window) =>
  some((bot) => userAgent.toLowerCase().includes(bot.toLowerCase()), knownBots);
