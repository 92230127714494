import type { Id } from "storefront/lib/Id";

export type Followings = {
  listings: Array<Id>;
  users: Array<Id>;
};
export const empty = {
  listings: [],
  users: [],
};
