import type { Id } from "../../../lib/Id";
import { get } from "../../request";

type Response = {
  data: {
    sessionId: Id;
  };
};

const unpack = ({ data }: Response): Id => data.sessionId;

/**
 * The `/api/session_id` endpoint returns the Rails session id. We primarily use this to report a session id to SiftScience for tracking users who aren't logged in.
 *
 * More on the Rails session id here:
 * https://guides.rubyonrails.org/v2.3.8/security.html#session-id
 *
 * More on SiftScience anonymous user tracking here:
 * https://sift.com/resources/tutorials/anonymous-users
 */
const id = (): Promise<Id> => get("/api/session_id").then(unpack);

export default id;
