/* eslint-disable no-underscore-dangle */
import { camelize } from "camelscore";
import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetServerSidePropsResult,
} from "next";
import React, { useEffect } from "react";
import { SWRConfig } from "swr";

export const initialServerData: Record<string, any> = {};

export function setInitialServerData<T>(key: string, value: T): T {
  initialServerData[key] = value;
  return value;
}

/**
 * Provides a wrapper context for prefilled data. Intended to be used in the root component of the application
 * This component acts as a generic provider that encapsulates and obscures the implementation details of our cache prefilling
 * It currently implements SWR but could in theory be swapped out for another caching library or strategy
 * @param data - The prefilled data to be provided to the children, should be a key-value pair with the key
 * being the exact path that was requested
 * @param children - The children to be wrapped by the provider
 * @returns The children wrapped in the provider
 */
const InitialDataProvider = ({
  children,
  data = {},
}: {
  children: React.ReactNode;
  data?: Record<string, unknown>;
}) => {
  const [initialData, setInitialData] = React.useState<Record<string, any>>({
    ...data,
  });

  useEffect(() => {
    if (window.__INITIAL_DATA__) {
      Object.keys(window.__INITIAL_DATA__).forEach((key) => {
        // Don't override data that was already provided directly
        if (!initialData[key]) {
          const newInitialData = {
            ...initialData,
            [key]: camelize(window.__INITIAL_DATA__[key]),
          };
          setInitialData(newInitialData);
        }
      });
    }
  }, [initialData]);

  return <SWRConfig value={{ fallback: initialData }}>{children}</SWRConfig>;
};

type InitialDataProps = Record<string, any>;

type WithInitialDataProps<P extends Record<string, any> = Record<string, any>> =
  P & {
    initialData: InitialDataProps;
  };

export const withInitialData = <
  P extends Record<string, any> = Record<string, any>,
>(
  getServerSidePropsFunc: GetServerSideProps<P>,
): GetServerSideProps<WithInitialDataProps<P>> => {
  return async (
    context: GetServerSidePropsContext,
  ): Promise<GetServerSidePropsResult<WithInitialDataProps<P>>> => {
    const result = await getServerSidePropsFunc(context);

    if ("props" in result) {
      const props =
        result.props instanceof Promise ? await result.props : result.props;
      return {
        ...result,
        props: {
          ...props,
          initialData: {
            ...initialServerData,
            ...(props.initialData ?? {}),
          },
        },
      };
    }

    return result;
  };
};

export default InitialDataProvider;
