import { each } from "lodash/fp";
import Properties from "storefront/Analytics/Properties";
import type { UserSelf } from "../User";
import type { Event } from "./Event";
import type { Tracker } from "./Tracker";

const initialize = (trackers: Array<Tracker>): Tracker => {
  const track = (event: Event): Event => {
    each((tracker) => tracker.track(event), trackers);
    return event;
  };

  const identify = (
    user?: UserSelf,
    properties?: Properties,
  ): UserSelf | undefined => {
    each((tracker) => tracker.identify(user, properties), trackers);
    return user;
  };

  return {
    track,
    identify,
  };
};

export default initialize;
