import tryCatch from "storefront/lib/Result/tryCatch";
import extract from "storefront/lib/Result/extract";
import isSuccess from "storefront/lib/Result/isSuccess";

/**
 * @namespace Storage
 */

/**
 * @private
 */
const onThrow = (error: unknown): Error => {
  if (error instanceof Error) return error;
  return new Error("Unknown Storage Error");
};

/**
 * @memberof Storage
 * @description
 * Attempts to get the given key from the given storage method (local storage, session storage, etc.).
 *
 * The attempt might fail depending on the user's browser and settings. This case should not be treated as exceptional and we should understand how the system behaves when the given storage method is not available.
 *
 * @see safelyGetItem if you don't care about explicitly handling failures.
 */
export const getItem = (storage: Storage) =>
  tryCatch(onThrow)((key: string) => storage.getItem(key));

/**
 * @memberof Storage
 * @description
 * Attempts to set the given value to the given key within the given storage method (local storage, session storage, etc.).
 *
 * The attempt might fail depending on the user's browser and settings. This case should not be treated as exceptional and we should understand how the system behaves when the given storage method is not available.
 *
 * @see safelySetItem if you don't care about explicitly handling failures.
 */
export const setItem = (storage: Storage) =>
  tryCatch(onThrow)((key: string, value: string) =>
    storage.setItem(key, value),
  );

/**
 * @memberof Storage
 * @description
 * Attempts to remove the given key from the given storage method (local storage, session storage, etc.).
 *
 * The attempt might fail depending on the user's browser and settings. This case should not be treated as exceptional and we should understand how the system behaves when the given storage method is not available.
 *
 * @see safelyRemoveItem if you don't care about explicitly handling failures.
 */
export const removeItem = (storage: Storage) =>
  tryCatch(onThrow)((key: string) => storage.removeItem(key));

/**
 * @memberof Storage
 * @description
 * Attempts to get the given key from the given storage method (local storage, session storage, etc.).
 *
 * The attempt might fail depending on the user's browser and settings. In the case that it fails, we will return `null`. The caller will not be able to tell the difference between the attempt failing and the attempt succeeding but not finding the key.
 *
 * @see getItem if you need to explicitly handle failures.
 */
export const safelyGetItem =
  (storage: Storage) =>
  (key: string): string | null => {
    const result = getItem(storage)(key);
    return extract(
      (): string | null => null,
      (value: string | null) => value,
    )(result);
  };

/**
 * @memberof Storage
 * @description
 * Attempts to set the given value to the given key within the given storage method (local storage, session storage, etc.).
 *
 * The attempt might fail depending on the user's browser and settings. In the case that it fails, we will return `false`. The caller will not know why the operation failed, only that it did fail.
 *
 * @see setItem if you need to explicitly different failure cases.
 */
export const safelySetItem =
  (storage: Storage) =>
  (key: string, value: string): boolean => {
    const result = setItem(storage)(key, value);
    return isSuccess(result);
  };

/**
 * @memberof Storage
 * @description
 * Attempts to remove the given key from the given storage method (local storage, session storage, etc.).
 *
 * The attempt might fail depending on the user's browser and settings. In the case that it fails, we will return `false`. The caller will not know why the operation failed, only that it did fail.
 *
 * @see removeItem if you need to explicitly different failure cases.
 */
export const safelyRemoveItem =
  (storage: Storage) =>
  (key: string): boolean => {
    const result = removeItem(storage)(key);
    return isSuccess(result);
  };
