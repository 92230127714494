/* eslint-disable import/prefer-default-export */
import { map } from "lodash/fp";
import type { Listing } from "../Listing";
import type { Categories } from "../Categories";

export type GrailedHits = {
  grailed: number;
  hype: number;
  basics: number;
};
const emptyGrailedHits: GrailedHits = {
  grailed: 0,
  hype: 0,
  basics: 0,
};
export type Listings = {
  hits: GrailedHits;
  listings: Array<Listing>;
  categories: Categories;
  isLoading: boolean;
  noMoreListings: boolean;
  initialPage: "";
  // DEPRECATED
  totalHits: number;
  initialTotalHits: number | null | undefined;
  feedRequester: ((...args: Array<any>) => any) | null | undefined;
  listingsNextPage: number | null | undefined;
};
export const empty: Listings = {
  hits: emptyGrailedHits,
  listings: [],
  categories: {},
  isLoading: false,
  noMoreListings: false,
  initialPage: "",
  // DEPRECATED
  totalHits: 0,
  initialTotalHits: null,
  feedRequester: () => {},
  listingsNextPage: null,
};
export const updateListing: (
  arg0: (arg0: Listing) => Listing,
) => (arg0: number) => (arg0: Listings) => Array<Listing> =
  (updater) =>
  (id) =>
  ({ listings }) =>
    map((listing) => {
      if (listing.id !== id) return listing;
      return updater(listing);
    }, listings);
