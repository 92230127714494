import type { UnreadCounts } from "storefront/GrailedAPI/v1/Conversations/getUnreadCounts";

export const FETCH_UNREAD_COUNTS_SUCCESS: "FETCH_UNREAD_COUNTS_SUCCESS" =
  "FETCH_UNREAD_COUNTS_SUCCESS";
export type FetchUnreadCountsSuccessAction = {
  type: typeof FETCH_UNREAD_COUNTS_SUCCESS;
  payload: UnreadCounts;
};
export const fetchUnreadCountsSuccessActionCreator = (
  payload: UnreadCounts,
): FetchUnreadCountsSuccessAction => ({
  type: FETCH_UNREAD_COUNTS_SUCCESS,
  payload,
});
