const MAXIMUM_RECENT_REQUESTS = 1;
const TIMEBOX = 200;
class RequestWatcher {
  recentRequests: Map<string, number> = new Map();

  subscribers: Set<(requestKey: string) => void> = new Set();

  subscribe = (subscriber: (requestKey: string) => void): (() => void) => {
    this.subscribers.add(subscriber);

    return () => {
      this.subscribers.delete(subscriber);
    };
  };

  addRequest = (url: string, body?: BodyInit) => {
    const requestKey = url + (body ? JSON.stringify(body) : "");

    const numberOfRecentRequests = this.recentRequests.get(requestKey) || 0;

    if (numberOfRecentRequests > MAXIMUM_RECENT_REQUESTS) {
      if (process.env.NODE_ENV === "development") {
        console.warn(`WARNING: More than one recent request to ${requestKey}`);
        this.subscribers.forEach((subscriber) => subscriber(requestKey));
      }
    }

    this.recentRequests.set(requestKey, numberOfRecentRequests + 1);

    setTimeout(() => {
      const numberOfRecentRequests = this.recentRequests.get(requestKey) || 0;

      if (numberOfRecentRequests <= 0) return;

      this.recentRequests.set(requestKey, numberOfRecentRequests - 1);
    }, TIMEBOX);
  };
}

export default RequestWatcher;
