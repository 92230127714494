import { UserSelf } from "../User";
import isInternalRoute from "../isInternalRoute";
import { CheckoutSuccessProduct, Event } from "./Event";
import { Tracker } from "./Tracker";
import { isKnownBot } from "./isKnownBot";
import Properties from "./Properties";

const ACCOUNT_ID = "CCQ7CVJC77U4AGK4KNH0";

/**
 * @name Analytics.TikTokTracker
 */
export class TikTokTracker implements Tracker {
  tiktok: TikTok;

  /**
   * @description The TikTok tag script is loaded by third_party_js/_pinterest.html.erb or Page/DocumentHead.tsx.
   */
  constructor(win: typeof window = window) {
    this.tiktok = win.ttq;
    if (this.tiktok) this.tiktok.load(ACCOUNT_ID);
  }

  identify = (user?: UserSelf, _properties: Properties = {}) => user;

  track = (event: Event): Event => {
    if (isInternalRoute()) return event;
    if (isKnownBot()) return event;

    if (event.object === "confirmation_page" && event.action === "viewed") {
      this.tiktok.track("CompletePayment", {
        content_type: "product",
        value: event.properties.soldPrice,
        quantity: event.properties.quantity,
        currency: event.properties.currency,
        contents: event.properties.products.map(
          (product: CheckoutSuccessProduct) => {
            return {
              id: `${product.product_id}`,
              quantity: product.quantity,
            };
          },
        ),
      });
    } else if (event.object === "product" && event.action === "viewed") {
      this.tiktok.track("ViewContent", {
        description: "Product Viewed",
        content_type: "product",
        content_id: `${event.properties.product_id}`,
      });
    } else if (event.object === "page" && event.action === "viewed") {
      this.tiktok.page();
    } else if (
      (event.object === "sign_up_step" && event.action === "completed") ||
      (event.object === "google_sign_up" && event.action === "completed") ||
      (event.object === "facebook_sign_up" && event.action === "completed") ||
      (event.object === "apple_sign_up" && event.action === "completed")
    ) {
      this.tiktok.track("CompleteRegistration");
    }

    return event;
  };
}

let instance: Tracker | undefined;
export const getInstance = (): Tracker => {
  if (!instance) instance = new TikTokTracker();
  return instance;
};
