/** IMPORTANT !!!
 * Please don't use Redux unless u have to ty :)
 */
// Fitting Room aka Favorites aka My Grails
export const FETCH_FITTING_ROOM_ERROR: "FETCH_FITTING_ROOM_ERROR" =
  "FETCH_FITTING_ROOM_ERROR";
export const FETCH_FITTING_ROOM_REQUEST: "FETCH_FITTING_ROOM_REQUEST" =
  "FETCH_FITTING_ROOM_REQUEST";
export const FETCH_FITTING_ROOM_SUCCESS: "FETCH_FITTING_ROOM_SUCCESS" =
  "FETCH_FITTING_ROOM_SUCCESS";
export const FITTING_ROOM_FOLLOW_SELLER = "FITTING_ROOM_FOLLOW_SELLER";
export const FITTING_ROOM_SORT_UPDATED = "FittingRoom.ChangeSort.SORT_UPDATED";
export const FITTING_ROOM_UNFOLLOW_SELLER = "FITTING_ROOM_UNFOLLOW_SELLER";
export const FAVORITE_LISTING_ERROR = "FAVORITE_LISTING_ERROR";
export const FAVORITE_LISTING_REQUEST = "FAVORITE_LISTING_REQUEST";
export const FAVORITE_LISTING_SUCCESS = "FAVORITE_LISTING_SUCCESS";
export const MUTE_LISTING_ERROR = "MUTE_LISTING_ERROR";
export const MUTE_LISTING_REQUEST = "MUTE_LISTING_REQUEST";
export const MUTE_LISTING_SUCCESS = "MUTE_LISTING_SUCCESS";
export const NO_MORE_LISTINGS_FOR_FITTING_ROOM: "NO_MORE_LISTINGS_FOR_FITTING_ROOM" =
  "NO_MORE_LISTINGS_FOR_FITTING_ROOM";
export const UNMUTE_LISTING_ERROR = "UNMUTE_LISTING_ERROR";
export const UNFAVORITE_LISTING_ERROR = "UNFAVORITE_LISTING_ERROR";
export const UNFAVORITE_LISTING_REQUEST = "UNFAVORITE_LISTING_REQUEST";
export const UNFAVORITE_LISTING_SUCCESS = "UNFAVORITE_LISTING_SUCCESS";
export const UNMUTE_LISTING_REQUEST = "UNMUTE_LISTING_REQUEST";
export const UNMUTE_LISTING_SUCCESS = "UNMUTE_LISTING_SUCCESS";
export const VIEW_CAPSULES = "VIEW_CAPSULES";
export const VIEW_GRAILS = "VIEW_GRAILS";
export const VIEW_SEARCHES = "VIEW_SEARCHES";
export const VIEW_SELLERS = "VIEW_SELLERS";
export const VIEW_DESIGNERS = "VIEW_DESIGNERS";
// Capsules
export const CAPSULES_GET_ALL_CAPSULES_RESPONDED: "CAPSULES_GET_ALL_CAPSULES_RESPONDED" =
  "CAPSULES_GET_ALL_CAPSULES_RESPONDED";
export const CAPSULES_GET_ALL_CAPSULES_ERRORED: "CAPSULES_GET_ALL_CAPSULES_ERRORED" =
  "CAPSULES_GET_ALL_CAPSULES_ERRORED";
export const CAPSULES_CREATE_RESPONDED = "CAPSULES_CREATE_RESPONDED";
export const CAPSULES_CREATE_ERRORED = "CAPSULES_CREATE_ERRORED";
// CapsulesCMS
export const CAPSULES_CMS_MOUNTED: "CAPSULES_CMS_MOUNTED" =
  "CAPSULES_CMS_MOUNTED";
// CapsulesCMS.List
export const CAPSULES_CMS_LIST_WAYPOINT_ENTERED =
  "CapsulesCMS.List.WAYPOINT_ENTERED";
export const CAPSULES_CMS_LIST_CLICKED = "CapsulesCMS.List.Capsule.CLICKED";
// Filtering -- DEPRECATED
export const CLOSE_MANAGE_SAVED_FILTERS_MODAL =
  "CLOSE_MANAGE_SAVED_FILTERS_MODAL";
export const EDIT_SAVED_FILTER = "EDIT_SAVED_FILTER";
export const OPEN_MANAGE_SAVED_FILTERS_MODAL =
  "OPEN_MANAGE_SAVED_FILTERS_MODAL";
export const RENAME_SAVED_FILTER = "RENAME_SAVED_FILTER";
export const SET_DESIGNER_SEARCH_QUERY = "SET_DESIGNER_SEARCH_QUERY";
export const UPDATE_SAVED_FILTERS = "UPDATE_SAVED_FILTERS";
export const UPDATE_SORT = "UPDATE_SORT";
export const UPDATE_APPLIED_SEARCH_ID = "UPDATE_APPLIED_SEARCH_ID";
export const UPDATE_FILTER_NAMES = "UPDATE_FILTER_NAMES";
// Wardrobe
export const BLOCK_WARDROBE_USER_SUCCESS = "BLOCK_WARDROBE_USER_SUCCESS";
export const BLOCK_WARDROBE_USER_ERROR = "BLOCK_WARDROBE_USER_ERROR";
export const UNBLOCK_WARDROBE_USER_SUCCESS = "UNBLOCK_WARDROBE_USER_SUCCESS";
export const UNBLOCK_WARDROBE_USER_ERROR = "UNBLOCK_WARDROBE_USER_ERROR";
// Vacation Mode
export const TURN_VACATION_ON = "TURN_VACATION_ON";
export const TURN_VACATION_OFF = "TURN_VACATION_OFF";
